import Link from "next/link";
import Image from "next/image";

import { recordConversion, Conversion } from "utils/conversions";

export default function Footer({ siteData }) {
  return (
    <footer className="bg-white" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        {siteData.fields.title} - {siteData.fields.footerText}
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <div className="flex flex-row items-center">
              <Image
                src="/logo/cc-logo.png"
                alt="Cosmetic College"
                width={185}
                height={45}
                // layout="fixed"
              />
              <h6 className="sr-only font-sans font-semibold text-brand-500 text-2xl ml-2">
                {siteData.fields.title}
              </h6>
            </div>
            <p className="text-gray-500 text-base">
              {siteData.fields.footerText}
            </p>
            <div className="flex flex-col space-y-6">
              {siteData.fields.address && (
                <a
                  href="https://maps.app.goo.gl/kuj5qfqhGiiptKDV9"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-600 hover:text-gray-700 flex"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-6 w-6 block"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-gray-500 text-base block ml-2">
                    {siteData.fields.address}
                  </span>
                </a>
              )}
              <a
                href={`tel:+44${siteData.fields.phoneNumber
                  .replace(/^0+/, "")
                  .replace(/\s/g, "")}`}
                className="text-gray-600 hover:text-gray-700 flex"
              >
                <span className="sr-only">Phone number</span>
                <svg
                  className="h-6 w-6 block"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
                <span className="text-gray-500 text-base block ml-2">
                  {siteData.fields.phoneNumber}
                </span>
              </a>
              <a
                onClick={() => recordConversion({ send_to: Conversion.email })}
                href={`mailto:${siteData.fields.emailAddress}`}
                className="text-gray-600 hover:text-gray-700 flex"
              >
                <span className="sr-only">Email</span>
                <svg
                  className="h-6 w-6 block"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="text-gray-500 text-base block ml-2">
                  {siteData.fields.emailAddress}
                </span>
              </a>
              <a
                href={`mailto:${siteData.fields.supportEmailAddress}`}
                className="text-gray-600 hover:text-gray-700 flex"
              >
                <span className="sr-only">Email</span>
                <svg
                  className="h-6 w-6 block"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="text-gray-500 text-base block ml-2">
                  {siteData.fields.supportEmailAddress}
                </span>
              </a>
              <a
                href={`mailto:${siteData.fields.modelsEmailAddress}`}
                className="text-gray-600 hover:text-gray-700 flex"
              >
                <span className="sr-only">Email</span>
                <svg
                  className="h-6 w-6 block"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span className="text-gray-500 text-base block ml-2">
                  {siteData.fields.modelsEmailAddress}
                </span>
              </a>
            </div>
            <div className="flex space-x-6">
              <a
                target="_blank"
                rel="noreferrer"
                href={siteData.fields.socialMedia.facebook}
                title="Check us out on Facebook"
                className="text-gray-600 hover:text-gray-700"
              >
                <span className="sr-only">Facebook</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>

              <a
                rel="noreferrer"
                target="_blank"
                href={siteData.fields.socialMedia.instagram}
                title="Check us out on Instagram"
                className="text-gray-600 hover:text-gray-700"
              >
                <span className="sr-only">Instagram</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>

              <a
                rel="noreferrer"
                target="_blank"
                href={siteData.fields.socialMedia.youtube}
                title="Check us out on YouTube"
                className="text-gray-600 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  imageRendering="optimizeQuality"
                  shapeRendering="geometricPrecision"
                  textRendering="geometricPrecision"
                  viewBox="0 0 3333 3333"
                >
                  <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm913 1294s-18-129-74-185c-71-74-151-75-187-79-261-19-652-19-652-19h-1s-392 0-652 19c-36 4-116 5-187 79-56 56-74 185-74 185s-19 151-19 302v141c0 151 19 302 19 302s18 129 74 185c71 74 164 72 206 80 149 14 634 19 634 19s392-1 653-19c36-4 116-5 187-79 56-56 74-185 74-185s19-151 19-302v-141c0-151-19-302-19-302zm-1107 615v-524l504 263-504 261z" />
                </svg>
              </a>

              <a
                rel="noreferrer"
                target="_blank"
                href={siteData.fields.socialMedia.tiktok}
                title="Check us out on TikTok"
                className="text-gray-600 hover:text-gray-700"
              >
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" />
                </svg>
              </a>
            </div>
            <div className="flex flex-row items-center flex-wrap md:flex-nowrap">
              {siteData.fields.footerLogos.map((logo, key) => {
                return (
                  <div
                    className={`mr-4 w-24 h-16 relative ${
                      logo.fields.title === "Knoma" ? "-mt-2" : ""
                    }`}
                    key={`xw-${key}`}
                  >
                    <Image
                      src={`https:${logo.fields.file.url}`}
                      fill
                      alt={logo.fields.title}
                      className="object-scale-down object-center"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-12 md:mt-0 flex flex-col flex-wrap md:flex-row xl:col-span-2">
            {siteData.fields.footerLinks.columns.map((column, key) => (
              <div
                className="mt-5 pb-5 md:mt-0 lg:mt-5 flex-1"
                key={`co-${key}`}
              >
                <h3 className="text-sm font-semibold text-gray-400 uppercase">
                  {column.title}
                </h3>
                <ul className="mt-4 space-y-4">
                  {column.links.map((link, idx) => (
                    <li key={`li-${key}-${idx}`}>
                      <Link
                        href={link.link}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {link.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; {new Date().getFullYear()} Cosmetic College
          </p>
        </div>
      </div>
    </footer>
  );
}
