import { useEffect, useRef, useState, Fragment } from "react";
import Link from "next/link";
import Image from "next/image";

import { useRouter } from "next/router";
import { useOnClickOutside } from "utils/hooks/click-outside";
import { useSession } from "./providers/session";
import { Disclosure, Transition, Popover, Menu } from "@headlessui/react";
import {
  Bars3Icon,
  ChevronRightIcon,
  ChevronDownIcon,
  PhoneIcon,
  UserIcon,
  ViewfinderCircleIcon,
  XMarkIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import dynamic from "next/dynamic";
import { links } from "utils/site-data";

const HeaderSearch = dynamic(() => import("./organisms/header-search"));

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const desktopLinks = [
  "/courses/virtual-training-courses/",
  // "/courses/elearning/",
  // "/courses/packages/",
];
const BNRKEY = "cc.bk2";
const moreLinks = ["/for-business", "/models"];

export default function NavBar({ siteData }) {
  const ref = useRef(null);
  const router = useRouter();
  const session = useSession();
  const [state, setState] = useState({
    open: false,
    courses: false,
    search: false,
    call: false,
    banner: true,
  });

  useEffect(() => {
    // if (localStorage) {
    //   const v = localStorage.getItem(BNRKEY);
    //   setState({ ...state, banner: v !== "false" });
    // }
    const handleRouteChange = () => {
      setState((s) => ({ ...s, courses: false }));
      document.body.removeAttribute("style");
      document.body.click();
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  useOnClickOutside(ref, () => {
    if (state.courses) {
      setState({ ...state, courses: false });
    }
  });

  const message =
    "25% off VTCT/CPD Courses Code XMAS25 • Offer Ends Jan 1st • Code: XMAS25";

  return (
    <>
      <div className="bg-white fixed z-50 w-full shadow">
        {state.banner ? (
          <div className="flex items-center justify-between gap-x-6 bg-brand-800 px-6 py-2.5 sm:pr-3.5 lg:pl-8">
            <p className="text-sm/6 text-white">
              <a href="/courses/">
                <strong className="font-semibold">December Offers</strong>
                <svg
                  viewBox="0 0 2 2"
                  aria-hidden="true"
                  className="mx-2 inline h-0.5 w-0.5 fill-current"
                >
                  <circle r={1} cx={1} cy={1} />
                </svg>
                <strong className="font-semibold">{message} </strong>
                <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
            <button
              type="button"
              onClick={() => setState({ ...state, banner: false })}
              className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
            </button>
          </div>
        ) : null}
        <div className=" flex flex-col-reverse">
          <Disclosure
            as="nav"
            className="isolate relative flex items-center justify-start container mx-auto"
          >
            {({ open, close }) => (
              <div className=" flex flex-col w-full relative justify-center">
                <div className="flex flex-1 w-full">
                  <Link href="/" className="block flex-1 self-center">
                    <div className="flex flex-row items-center py-2 pt-3 pl-4 xl:pl-0">
                      <span className="sr-only">{siteData.fields.title}</span>
                      <Image
                        src="/logo/cc-logo.png"
                        alt="The Cosmetic College"
                        width={165}
                        height={35}
                      />
                      <h3 className="sr-only font-semibold text-brand-500 text-2xl ml-2">
                        {siteData.fields.title}
                      </h3>
                    </div>
                  </Link>
                  <div className="flex">
                    <div
                      id="mob"
                      className="hidden md:ml-8 sm:text-sm h-full md:flex divide-x divide-slate-200 border-l border-r border-slate-200"
                    >
                      {links.map((item, index) => {
                        if (
                          (item.children && item.text === "Training Courses") ||
                          (item.children && item.text === "Who We Train")
                        ) {
                          return (
                            <Popover
                              className="isolate z-50 h-full"
                              key={`md-z=%${index}`}
                            >
                              <Popover.Button
                                className={classNames(
                                  open ? "text-gray-900" : "text-slate-800",
                                  "flex text-sm group items-center bg-white font-medium hover:text-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 p-4 py-5"
                                )}
                              >
                                {item.text}
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? "text-gray-600" : "text-gray-400",
                                    "ml-2 h-4 w-4 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              </Popover.Button>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 -translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-1"
                              >
                                <Popover.Panel
                                  className="absolute inset-x-0 top-0 -z-50 mt-16"
                                  unmount={false}
                                >
                                  <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-6 lg:grid-cols-2 lg:pl-4 lg:pr-6 bg-white shadow-lg ring-1 ring-gray-900/5 border-t-2 border-brand-600">
                                    <div className="grid grid-cols-2 gap-y-0 gap-x-6 sm:gap-x-8">
                                      <div
                                        className={`${
                                          index === 5 ? "grid-cols-2" : ""
                                        } col-span-2 grid-cols-2 elative grid gap-2 bg-white`}
                                      >
                                        {item.children.map((child, y) => (
                                          <span
                                            className={`${
                                              !desktopLinks.includes(child.link)
                                                ? "lg:inline"
                                                : "xl:hidden inline"
                                            } ${
                                              !moreLinks.includes(child.link)
                                                ? "inline"
                                                : "lg:hidden inline"
                                            }`}
                                            key={`ch-md-${y}`}
                                          >
                                            <Popover.Button
                                              as={Link}
                                              href={child.link}
                                              // onClick={() => close()}
                                              className="flex items-start rounded-lg py-2 transition duration-150 ease-in-out hover:bg-slate-100"
                                            >
                                              <div className="ml-4">
                                                <p className="text-sm font-semibold text-neutral-900">
                                                  {child.text}
                                                </p>
                                                <p className="mt-1 text-xs text-gray-500">
                                                  {child.description}
                                                </p>
                                              </div>
                                            </Popover.Button>
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-10 sm:gap-8 lg:grid-cols-2">
                                      <h3 className="sr-only">
                                        New Training Courses
                                      </h3>
                                      {item.additional &&
                                        item.additional.map((post) => (
                                          <article
                                            key={post.id}
                                            className="relative isolate flex max-w-2xl flex-col gap-x-8 gap-y-4 sm:flex-row sm:items-start lg:flex-col lg:items-stretch"
                                          >
                                            <div className="relative flex-none">
                                              <img
                                                className="aspect-[2/1] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[16/9] sm:h-32 lg:h-auto"
                                                src={post.imageUrl}
                                                alt=""
                                              />
                                              <div className="absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                            <div>
                                              <h4 className="text-sm font-semibold leading-6 text-gray-900">
                                                <a href={post.href}>
                                                  <span className="absolute inset-0" />
                                                  {post.title}
                                                </a>
                                              </h4>
                                              <div className="flex items-center gap-x-4">
                                                <a
                                                  href={post.category.href}
                                                  className="sr-only relative z-10 rounded-full bg-brand-600 px-0 py-0 text-xs font-medium text-white hover:bg-brand-400"
                                                >
                                                  {post.category.title}
                                                </a>
                                                <time
                                                  dateTime={post.datetime}
                                                  className="sr-only text-sm leading-6 text-gray-600"
                                                >
                                                  {post.date}
                                                </time>
                                              </div>
                                              <p className="mt-2 text-sm leading-6 text-gray-600">
                                                {post.description}
                                              </p>
                                            </div>
                                          </article>
                                        ))}
                                    </div>
                                  </div>
                                  <div className="w-full grid grid-cols-2 bg-slate-50 sm:flex space-y-0 border-b-2 divide-x-2 divide-slate-100 border border-slate-200 rounded-b-md max-w-7xl mx-auto">
                                    <div className="flow-root w-full col-span-1 overflow-hidden">
                                      <Link
                                        href={item.link}
                                        onClick={() => close()}
                                        className="w-full -m-3 flex justify-center items-center rounded-md p-8 text-base font-semibold text-gray-900 transition duration-150 ease-in-out hover:bg-slate-100"
                                      >
                                        <ViewfinderCircleIcon
                                          className="h-4 w-4 flex-shrink-0 text-slate-600"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-2 whitespace-nowrap text-xs">
                                          View All Courses
                                        </span>
                                      </Link>
                                    </div>
                                    <div className="flow-root w-full col-span-1 overflow-hidden">
                                      <a
                                        href={`tel:+44${siteData.fields.phoneNumber
                                          .replace(/^0+/, "")
                                          .replace(/\s/g, "")}`}
                                        className="-m-3 flex justify-center items-center rounded-md p-8 text-base font-semibold text-gray-900 transition duration-150 ease-in-out hover:bg-slate-100"
                                      >
                                        <PhoneIcon
                                          className="h-4 w-4 flex-shrink-0 text-slate-600"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-2 whitespace-nowrap text-xs">
                                          {siteData.fields.phoneNumber}
                                        </span>
                                      </a>
                                    </div>
                                    <div className="flow-root w-full col-span-1 overflow-hidden">
                                      <a
                                        href="/request-a-callback"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="-m-3 flex justify-center items-center rounded-md p-8 text-base font-semibold text-gray-900 transition duration-150 ease-in-out hover:bg-slate-100"
                                      >
                                        <CalendarIcon
                                          className="h-4 w-4 flex-shrink-0 text-slate-600"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-2 whitespace-nowrap text-xs">
                                          Meet With A Course Advisor
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </Popover>
                          );
                        }
                        if (item.children) {
                          return (
                            <Popover key={`nav-${index}`} className="relative">
                              {({ open }) => (
                                <>
                                  <Popover.Button
                                    className={classNames(
                                      open ? "text-gray-900" : "text-slate-800",
                                      "text-sm group flex items-center bg-white font-medium hover:text-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 p-4 py-5"
                                    )}
                                  >
                                    <span>{item.text}</span>
                                    <ChevronDownIcon
                                      className={classNames(
                                        open
                                          ? "text-gray-600"
                                          : "text-gray-400",
                                        "ml-2 h-4 w-4 group-hover:text-gray-500"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </Popover.Button>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                  >
                                    <Popover.Panel
                                      className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0"
                                      unmount={false}
                                    >
                                      {({ close }) => (
                                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                          <div
                                            className={`${
                                              index === 5 ? "grid-cols-2" : ""
                                            } relative grid gap-5 bg-white px-3 py-4`}
                                          >
                                            {item.children.map((child, y) => (
                                              <span
                                                className={`${
                                                  !desktopLinks.includes(
                                                    child.link
                                                  )
                                                    ? "lg:inline"
                                                    : "xl:hidden inline"
                                                } ${
                                                  !moreLinks.includes(
                                                    child.link
                                                  )
                                                    ? "inline"
                                                    : "lg:hidden inline"
                                                }`}
                                                key={`ch-${y}`}
                                              >
                                                <Popover.Button
                                                  as={Link}
                                                  href={child.link}
                                                  className="-m-3 flex items-start rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                                >
                                                  <div className="ml-4">
                                                    <p className="text-sm font-medium text-gray-900">
                                                      {child.text}
                                                    </p>
                                                    <p className="mt-1 text-xs text-gray-500">
                                                      {child.description}
                                                    </p>
                                                  </div>
                                                </Popover.Button>
                                              </span>
                                            ))}
                                          </div>
                                          <div className="grid grid-cols-2 bg-gray-50 sm:flex space-y-0">
                                            <div className="flow-root w-full col-span-1">
                                              <Popover.Button
                                                as={Link}
                                                href={item.link}
                                                className="w-full -m-3 flex justify-center items-center rounded-md p-8 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-100"
                                              >
                                                <ViewfinderCircleIcon
                                                  className="h-4 w-4 flex-shrink-0 text-gray-400"
                                                  aria-hidden="true"
                                                />
                                                <span className="ml-2 whitespace-nowrap text-xs">
                                                  View All Courses
                                                </span>
                                              </Popover.Button>
                                            </div>
                                            <div className="flow-root w-full col-span-1">
                                              <a
                                                href={`tel:+44${siteData.fields.phoneNumber
                                                  .replace(/^0+/, "")
                                                  .replace(/\s/g, "")}`}
                                                className="-m-3 flex justify-center items-center rounded-md p-8 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-100"
                                              >
                                                <PhoneIcon
                                                  className="h-4 w-4 flex-shrink-0 text-gray-400"
                                                  aria-hidden="true"
                                                />
                                                <span className="ml-2 whitespace-nowrap text-xs">
                                                  {siteData.fields.phoneNumber}
                                                </span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popover.Panel>
                                  </Transition>
                                </>
                              )}
                            </Popover>
                          );
                        }
                        return (
                          <span
                            className={`${
                              !desktopLinks.includes(item.link)
                                ? "lg:inline"
                                : "hidden xl:inline"
                            } ${
                              !moreLinks.includes(item.link)
                                ? "xl:inline"
                                : "hidden xl:inline"
                            }`}
                            key={`nav-${index}`}
                          >
                            <Link
                              href={item.link}
                              onClick={() => close()}
                              className="p-4 py-5 whitespace-nowrap inline-flex items-center text-sm font-medium text-slate-800 hover:text-brand-500"
                            >
                              {item.text}
                            </Link>
                          </span>
                        );
                      })}
                      {!router.asPath.includes("checkout") &&
                        session &&
                        session.selectedCourses.length > 0 && (
                          <Link
                            href="/checkout/"
                            className="flex px-4 items-center justify-center"
                          >
                            <div className="relative h-8 w-8 rounded-full text-brand-600 flex bg-brand-200 justify-center items-center hover:text-slate-800">
                              {session.selectedCourses &&
                                session.selectedCourses.length && (
                                  <div className="absolute -top-1 -left-1 w-4 h-4 bg-blue-500 text-white rounded-full flex items-center justify-center text-[10px] font-semibold">
                                    {session.selectedCourses.length}
                                  </div>
                                )}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                />
                              </svg>
                            </div>
                            <span className="hidden lg:block ml-2 py-5 whitespace-nowrap items-center text-sm font-medium text-slate-800 hover:text-brand-500">
                              Book Courses
                            </span>
                          </Link>
                        )}
                    </div>
                  </div>
                  <div className="flex items-center md:hidden">
                    {!router.asPath.includes("checkout") &&
                      session &&
                      session.selectedCourses.length > 0 && (
                        <Link href="/checkout/" className="flex mr-2">
                          <div className="relative h-8 w-8 rounded-full text-brand-600 flex bg-brand-200 justify-center items-center hover:text-gray-200">
                            {session.selectedCourses &&
                              session.selectedCourses.length && (
                                <div className="absolute -top-1 -left-1 w-4 h-4 bg-blue-500 text-white rounded-full flex items-center justify-center text-[10px] font-semibold">
                                  {session.selectedCourses.length}
                                </div>
                              )}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              className="w-4 h-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                              />
                            </svg>
                          </div>
                        </Link>
                      )}
                    <Menu
                      as="div"
                      className="2xl:hidden rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                    >
                      <div>
                        <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <UserIcon className="h-6 rounded-full mr-2" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {!session.user && (
                            <>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="/sign-up/"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    Register
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="/sign-in/"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    Sign in
                                  </Link>
                                )}
                              </Menu.Item>
                            </>
                          )}
                          {session.user && (
                            <>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="/account/"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    Account
                                  </Link>
                                )}
                              </Menu.Item>

                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    onClick={() => session.logout()}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    Sign out
                                  </a>
                                )}
                              </Menu.Item>
                            </>
                          )}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    {/* Mobile menu button */}
                    <Disclosure.Button
                      onClick={() => setState({ ...state, search: false })}
                      className="lex items-center justify-center rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6 mr-4"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
                {!state.search && (
                  <Disclosure.Panel className="overflow-hidden shadow-md flex flex-col px-0 pb-0 md:hidden fixed bg-white z-[999999] border rounded-lg border-gray-300 h-[95vh] pt-2 top-14 left-0 w-screen">
                    {({ close }) => (
                      <>
                        <div className="p-2 px-5 font-semibold flex justify-between border-b border-slate-300">
                          <p>Cosmetic College</p>
                          <XMarkIcon
                            onClick={() => close()}
                            className="h-6 w-6 cursor-pointer"
                          />
                        </div>
                        <div className="flex-1 overflow-y-scroll overflow-x-hidden flex flex-col pt-2">
                          <div className="flex-1">
                            {links.map((item: any, index) => {
                              if (item.grid) {
                                return (
                                  <div
                                    className="grid grid-cols-2 gap-x-4 px-5 mt-3 pt-2 border-t border-slate-300"
                                    key={`ct-${index}`}
                                  >
                                    {item.children.map((child, y) => (
                                      <Link
                                        href={child.link}
                                        onClick={() => close()}
                                        key={`nav-grid=${y}`}
                                        className="flex items-start rounded-lg py-2 transition duration-150 ease-in-out hover:bg-slate-100"
                                      >
                                        <div className="">
                                          <p className="text-sm font-semibold text-neutral-900">
                                            {child.text}
                                          </p>
                                          <p className="mt-1 text-xs text-gray-500">
                                            {child.description}
                                          </p>
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                );
                              } else if (
                                item.mobile !== false &&
                                item.children &&
                                item.children.length > 0
                              ) {
                                return (
                                  <div
                                    key={`ct-${index}`}
                                    className={`${
                                      index === 5 ? "grid-cols-2" : ""
                                    } col-span-2 grid-cols-1 relative grid gap-2 bg-white px-1`}
                                  >
                                    {item.children.map((child, y) => (
                                      <span
                                        className={`${
                                          !desktopLinks.includes(child.link)
                                            ? "lg:inline"
                                            : "xl:hidden inline"
                                        } ${
                                          !moreLinks.includes(child.link)
                                            ? "inline"
                                            : "lg:hidden inline"
                                        }`}
                                        key={`ch-${y}`}
                                      >
                                        <Link
                                          href={child.link}
                                          onClick={() => close()}
                                          className="flex items-start rounded-lg py-1 transition duration-150 ease-in-out hover:bg-slate-100"
                                        >
                                          <div className="ml-4">
                                            <p className="text-sm font-semibold text-neutral-900">
                                              {child.text}
                                            </p>
                                            <p className="mt-1 text-xs text-gray-500">
                                              {child.description}
                                            </p>
                                          </div>
                                        </Link>
                                      </span>
                                    ))}
                                  </div>
                                );
                              } else if (item.mobile !== false) {
                                return (
                                  <Disclosure.Button
                                    className="mt-2 first:mt-0 p-2 rounded-lg hover:bg-gray-50 cursor-pointer w-full"
                                    key={`mb-${index}`}
                                  >
                                    <Link
                                      href={item.link}
                                      onClick={() => close()}
                                      className="-m-3 flex items-start rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                    >
                                      <div className="ml-4">
                                        <p className="text-sm font-medium text-gray-900">
                                          {item.mobile
                                            ? item.mobile
                                            : item.text}
                                        </p>
                                      </div>
                                    </Link>
                                  </Disclosure.Button>
                                );
                              }
                            })}
                          </div>
                          <div className="space-y-4 px-6 pt-2 pb-12 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8 rounded-lg ">
                            <div className="flow-root">
                              <Link
                                href="/courses"
                                onClick={() => close()}
                                className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out bg-gray-50 hover:bg-gray-100 justify-center"
                              >
                                <ViewfinderCircleIcon
                                  className="h-4 w-4 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-2 text-sm whitespace-nowrap">
                                  View All Courses
                                </span>
                              </Link>
                            </div>
                            <div className="flow-root">
                              <a
                                href="/request-a-callback"
                                target="_blank"
                                rel="noreferrer"
                                className="-m-3 flex mt-1 items-center justify-center rounded-md p-3 text-base font-medium text-white transition duration-150 ease-in-out bg-brand-600"
                              >
                                <CalendarIcon
                                  className="h-4 w-4 flex-shrink-0 text-white"
                                  aria-hidden="true"
                                />
                                <span className="ml-2 font-normal text-sm">
                                  Meet With A Course Advisor
                                </span>
                              </a>
                            </div>
                            <div className="flow-root">
                              <a
                                href={`tel:+44${siteData.fields.phoneNumber
                                  .replace(/^0+/, "")
                                  .replace(/\s/g, "")}`}
                                className="-m-3 flex mt-1 items-center justify-center rounded-md p-3 text-base font-medium text-white transition duration-150 ease-in-out bg-brand-600"
                              >
                                <PhoneIcon
                                  className="h-4 w-4 flex-shrink-0 text-white"
                                  aria-hidden="true"
                                />
                                <span className="ml-2 font-normal text-sm">
                                  {siteData.fields.phoneNumber}
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Disclosure.Panel>
                )}
              </div>
            )}
          </Disclosure>
          <div className="isolate relative flex flex-1 items-center justify-between sm:justify-between bg-slate-100 w-full py-3 px-4 md:px-6 shadow">
            <div className="container mx-auto flex items-center justify-between space-x-4">
              <HeaderSearch
                show={true}
                close={() => setState({ ...state, search: false })}
              />
              <div>
                {!session.user && (
                  <div className="hidden md:inline-flex space-x-4">
                    <Link
                      href="/sign-up/"
                      className="whitespace-nowrap inline-flex items-center py-2 px-4 font-medium bg-brand-700  text-xs rounded-full text-white hover:text-brand-800 hover:bg-brand-200 transition-all duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Register
                    </Link>
                    <Link
                      href="/sign-in/"
                      className="whitespace-nowrap inline-flex items-center py-2 px-4 font-medium bg-brand-700  text-xs rounded-full text-white hover:text-brand-800 hover:bg-brand-200 transition-all duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Sign in
                    </Link>
                  </div>
                )}
                {session.user && (
                  <div className="hidden md:inline-flex space-x-4">
                    <Link
                      href="/account/"
                      className="whitespace-nowrap inline-flex items-center py-2 px-4 font-medium bg-brand-700  text-xs rounded-full text-white hover:text-brand-800 hover:bg-brand-200 transition-all duration-150 ease-in-out"
                    >
                      Your Account
                    </Link>
                    <a
                      onClick={() => session.logout()}
                      className="whitespace-nowrap inline-flex items-center py-2 px-4 font-medium hover:text-brand-500 bg-brand-200  text-xs rounded-full text-brand-700"
                    >
                      Sign out
                    </a>
                  </div>
                )}
              </div>
              <a
                href={`tel:+44${siteData.fields.phoneNumber
                  .replace(/^0+/, "")
                  .replace(/\s/g, "")}`}
                className="whitespace-nowrap inline-flex items-center py-2 px-4 font-medium bg-brand-700  text-xs rounded-full text-white hover:text-brand-800 hover:bg-brand-200 transition-all duration-150 ease-in-out"
              >
                <span className="sr-only">Contact Us</span>
                <PhoneIcon className="h-4" aria-hidden="true" />
                <span className="ml-2 whitespace-nowrap inline-flex">
                  {siteData.fields.phoneNumber}
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="sr-only overflow-hidden relative -z-50" id="sr-nav">
          {links.map((item: any, index) => {
            if (item.children && item.children.length > 0) {
              // console.log("item", item);
              return item.children.map((child: any, index: number) => {
                return (
                  <Link
                    key={`srmb-${index}`}
                    href={child.link}
                    onClick={() => close()}
                    className="-m-3 flex items-start rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                  >
                    <div className="ml-4">
                      <p className="text-sm font-medium text-gray-900">
                        {child.mobile ? child.mobile : child.text}
                      </p>
                    </div>
                  </Link>
                );
              });
            } else {
              return (
                <Link
                  key={`srmb-${index}`}
                  href={item.link}
                  onClick={() => close()}
                  className="-m-3 flex items-start rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                >
                  <div className="ml-4">
                    <p className="text-sm font-medium text-gray-900">
                      {item.mobile ? item.mobile : item.text}
                    </p>
                  </div>
                </Link>
              );
            }
          })}
          <a href="https://www.cosmetic.college/courses/new/">
            New Training Courses At The Cosmetic College
          </a>
          <a href="https://www.cosmetic.college/courses/aesthetics/level-4-diploma-in-aesthetic-procedures-for-skin-rejuvenation/">
            Level 4 Certificate In Aesthetic Practice
          </a>
          <a href="https://www.cosmetic.college/courses/aesthetics/level-5-certificate-in-aesthetic-practice/">
            Level 5 Certificate In Aesthetic Practice
          </a>
          <a href="https://www.cosmetic.college/courses/aesthetics/level-7-certificate-in-aesthetic-practice/">
            Level 7 Certificate In Aesthetic Practice
          </a>
        </div>
      </div>
      {/* {state.banner ? (
        <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 mt-[115px] md:mt-[125px] -mb-[120px]">
          <div
            className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>
          <div
            className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
            <p className="text-sm leading-6 text-gray-900">
              <strong className="font-semibold">Special Offer!</strong>
              <svg
                viewBox="0 0 2 2"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
                aria-hidden="true"
              >
                <circle cx={1} cy={1} r={1} />
              </svg>
              Get 25% off 2+ courses - MULTIBUY25 Get 35% off 4+ courses -
              MULTIBUY35
            </p>
            <a
              href="/courses"
              className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            >
              View courses <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
          <div className="flex flex-1 justify-end">
            <button
              type="button"
              onClick={() => {
                setState({ ...state, banner: false });
                localStorage.setItem(BNRKEY, "false");
              }}
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
            </button>
          </div>
        </div>
      ) : null} */}
    </>
  );
}

const Accordian = ({ item, close }) => {
  return (
    <Popover key={`nav-${item.text}`} className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              !open ? "text-gray-900" : "text-gray-500",
              "-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 w-full"
            )}
          >
            <div className="ml-4">
              <p className="text-left text-sm font-medium text-gray-900">
                {item.mobile ? item.mobile : item.text}
              </p>
            </div>
            {!open ? (
              <ChevronRightIcon
                className={
                  "text-gray-400 ml-2 h-4 w-4 group-hover:text-gray-500"
                }
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className={
                  "text-gray-400 ml-2 h-4 w-4 group-hover:text-gray-500"
                }
                aria-hidden="true"
              />
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="mt-2">
              <div className="px-0 rounded-lg cursor-pointer w-full">
                {item.children.map((child, y) => {
                  return (
                    <span
                      className={`${
                        !desktopLinks.includes(child.link)
                          ? "lg:inline"
                          : "hidden xl:inline"
                      } ${
                        !moreLinks.includes(child.link)
                          ? "xl:inline"
                          : "hidden xl:inline"
                      }`}
                      key={`ch-${y}`}
                    >
                      <Link
                        href={child.link}
                        onClick={() => close()}
                        className="-m-3 flex items-start rounded-lg p-2 my-1 transition duration-150 ease-in-out hover:bg-gray-50 w-full"
                      >
                        <div className="ml-4">
                          <p className="text-left text-sm font-medium text-gray-900">
                            {child.text}
                          </p>
                          <p className="text-left mt-1 text-xs text-gray-500">
                            {child.description}
                          </p>
                        </div>
                      </Link>
                    </span>
                  );
                })}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

const Details = ({ item, close }) => {
  return (
    <div className="p-2 cursor-pointer w-full border-t border-gray-200">
      <div className="grid grid-cols-2 gap-6 mt-2 ml-2 justify-center">
        {item.children.map((child, y) => {
          return (
            <span
              className={`${
                !desktopLinks.includes(child.link)
                  ? "lg:inline"
                  : "hidden xl:inline"
              } ${
                !moreLinks.includes(child.link)
                  ? "xl:inline"
                  : "hidden xl:inline"
              }`}
              key={`ch-${y}`}
            >
              <Link
                href={child.link}
                onClick={() => close()}
                className="-m-3 flex items-start rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50"
              >
                <p className="text-sm font-normal text-gray-900">
                  {child.text}
                </p>
              </Link>
            </span>
          );
        })}
      </div>
    </div>
  );
};
