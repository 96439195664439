export function klayAction(data) {
  try {
    if (!data) {
      return null;
    }

    return {
      ProductName: data.fields.title,
      ProductID: data.selectedEvent ? data.selectedEvent.id : data.sys.id,
      ProductURL: `https://www.cosmetic.college${data.fields.url}`,
      Categories:
        data.fields.categories &&
        data.fields.categories.map((x) =>
          x
            .replace(/-/g, " ")
            .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase())
        ),
      ImageURL: data.fields.listingImage
        ? `https:${data.fields.listingImage.fields.file.url}`
        : `https:${data.fields.metadata.fields.image.fields.file.url}`,
      URL: window.location.href,
      Brand: "Cosmetic College",
      Price: data.selectedEvent
        ? data.selectedEvent.price / 100
        : data.fields.content.find(
            (b) => b.sys.contentType.sys.id === "pricing"
          ).fields.price,
    };
  } catch (e) {
    console.error(e);
    return {};
  }
}
