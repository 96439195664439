export const courseData = [
  {
    id: "31d244fe-71b0-4086-8207-88cb346c8f50",
    title: "Hair Filler",
    url: "https://www.cosmetic.college/courses/aesthetics/hair-filler-training-course/",
    contentful_id: "5VKqjD2g9sO0xQj6ZKr8R1",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F4MoNVP2Hg13MYnIpS2vPMb%2F7b12b718a54c6192053c00c65ea54bc9%2FUntitled_design__3_.png&w=3840&q=75",
  },
  {
    id: "401d53ef-bf82-43cd-ba9b-cd9c5ed8a285",
    title: "Plasma Bio Filler",
    url: "https://www.cosmetic.college/courses/aesthetics/plasma-bio-filler/",
    contentful_id: "570VIPZAn1A1jJI8JpM4f",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6pmKfm4RxxHGSu0vkYp36J%2F88dbe7a0b450baa58413ed697bce1f0e%2Fplasma-filler.webp&w=3840&q=75",
  },
  {
    id: "4c510f74-1504-432e-96cf-4ea1c181f75d",
    title: "Mesotoxin (Jalubotox)",
    url: "https://www.cosmetic.college/courses/aesthetics/mesotoxin/",
    contentful_id: "5hDjv4T2dYdazsJ5iLwAOF",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2WJranp5hovGGvh5cMWWi2%2F4dbc8d59a3a69c43bca6ee276399e432%2FScreenshot_2022-10-09_at_19.42.35.png&w=3840&q=75",
  },
  {
    id: "17b212da-1bee-4013-8bcf-69b92a29547f",
    title: "Mesotherapy",
    url: "https://www.cosmetic.college/courses/aesthetics/mesotherapy-training/",
    contentful_id: "3bthVz0uI3LUbvfXYxD8F5",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5ZbkfgcAzDs0OspWGwFlSC%2F6dbac1978141b79a8c102b2abbcebb14%2FScreenshot_2022-10-09_at_16.19.14.png&w=3840&q=75",
  },
  {
    id: "df9165d3-610a-4811-b718-1cc860671881",
    title: "IV Vitamin Drip Training",
    url: "https://www.cosmetic.college/courses/aesthetics/iv-vitamin-infusion-training/",
    contentful_id: "4FYgRK0mYuob2LL7HJZ8aj",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7ogZDVppM14PSD5RHBRIZa%2F5e2b11626d676ab58a8c23fcf3121cce%2Fiv-vitamin-drip-training.webp&w=3840&q=75",
  },
  {
    id: "da251a00-63d9-4f46-b87b-a25b65e77668",
    title: "PDO Mono Threads",
    url: "https://www.cosmetic.college/courses/aesthetics/pdo-mono-threads/",
    contentful_id: "5hWgWKtqeO5YzMpa1R74Uh",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2rx1XUYq6R9OLXagclitup%2Fa63494fee3689ece9bd8a9034965bd79%2FScreenshot_2022-10-17_at_21.02.13.png&w=3840&q=75",
  },
  {
    id: "ea4181be-a4ee-46fa-ad0b-9c9c397dd306",
    title: "PDO COG Thread Lift",
    url: "https://www.cosmetic.college/courses/aesthetics/pdo-cog-threads/",
    contentful_id: "2lS7vKutDn8JNvSAKxSnDD",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F21LAvDuTwEPgeyu3xxX7cW%2F7f9b84808f7b9a39427a7edb255cb405%2Fpdo-cogs.jpeg&w=3840&q=75",
  },
  {
    id: "9f07b96f-6a0d-487c-b84d-3aabb2daacf6",
    title: "Fox Eye Thread Lift",
    url: "https://www.cosmetic.college/courses/aesthetics/fox-eye-thread-lift/",
    contentful_id: "6ohXwWudQTRsoehRuLCF8U",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7yawEPier82jTjuQasd520%2Fbd1edbb90d71264166ed4290e3596dd4%2FUntitled_design.png&w=3840&q=75",
  },
  {
    id: "710d12a9-69a5-4275-a7e8-434ba986b1db",
    title: "Face & Body Profhilo",
    url: "https://www.cosmetic.college/courses/aesthetics/body-profhilo/",
    contentful_id: "70ASmxfUOQP5hvxx3Jkaw6",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FznR3EXjRn50pVtHEej03H%2F08a1b93b94864ca61a0044b66f1cc20f%2FUntitled_design__20_.png&w=3840&q=75",
  },
  {
    id: "6119b3e9-7077-47df-b2e6-62162d5addf3",
    title: "Sunekos",
    url: "https://www.cosmetic.college/courses/aesthetics/skin-boosters/",
    contentful_id: "2kVBdfGqUzKlSb2bO7ACM9",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5IvYAO9m7Jz5jvfpjLu1tC%2Fc7053271c0942fa9885afb79942c123f%2FIMG_1836.JPG&w=3840&q=75",
  },
  {
    id: "6119b3e9-7077-47df-b2e6-62162d5addf3",
    title: "Jalupro",
    url: "https://www.cosmetic.college/courses/aesthetics/skin-boosters/",
    contentful_id: "2kVBdfGqUzKlSb2bO7ACM9",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5IvYAO9m7Jz5jvfpjLu1tC%2Fc7053271c0942fa9885afb79942c123f%2FIMG_1836.JPG&w=3840&q=75",
  },
  {
    id: "ab170dd8-6132-4873-9392-f9cf87f114c0",
    title: "Laser Hair Removal",
    url: "https://www.cosmetic.college/courses/beauty/laser-hair-removal/",
    contentful_id: "5MrEz8xx8s0YFtNHRCes3i",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F3fp2bgY1mgeIycFMKZu6Ff%2Faebfcbc13a813cef831e6f587d627b1b%2FScreenshot_2022-09-25_at_10.00.34.png&w=3840&q=75",
  },
  {
    id: "8ab7870c-f7d3-4785-977f-345349e86459",
    title: "Lymphatic Drainage Massage",
    url: "https://www.cosmetic.college/courses/beauty/lymphatic-drainage-massage/",
    contentful_id: "488iYiL8DQJhMYKq2uYSJu",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1GbfeqG0LKZBze8uwukvAz%2F849b0b4f1e5f719aafd1b007376b3f42%2Fdeep-tissue-massage_274689-10676.jpeg&w=3840&q=75",
  },
  {
    id: "346db466-4615-4af0-92e4-5f21d661c323",
    title: "Cryotherapy (Fat Freezing)",
    url: "https://www.cosmetic.college/courses/beauty/cryotherapy-fat-freezing/",
    contentful_id: "5CFmmQpPtRwh4KGSQQzK4V",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FxCagOuhgXiCr6UXqAg6GF%2Fa1daae4f2d6e3f6cfc7f273f5a4bb25b%2FIMG_2281.JPG&w=3840&q=75",
  },
  {
    id: "decc8deb-f737-47c0-87b7-27182d43a377",
    title: "Body Sculpting",
    url: "https://www.cosmetic.college/courses/beauty/body-sculpting-cavitation-fat-reduction-and-skin-tightening/",
    contentful_id: "4KqUlfqwadKPLwapzVjS8C",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F11SBdmyIY80N6K9Quhn3W9%2F2c7743311ea0964593bb49faa509bc44%2FWoman-getting-an-anti-cellulite-lase-treatment-at-the-spa-1296x728-header.webp&w=3840&q=75",
  },
  {
    id: "afebe317-9752-471e-a278-ef00968c291e",
    title: "Radiofrequency Skin Tightening",
    url: "https://www.cosmetic.college/courses/beauty/radiofrequency-skin-tightening/",
    contentful_id: "1iJgY9PO5WqDGsskW6VzVi",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2FYSJD8niJXblRHQevm8o4%2F4d225e2c83635fc7d03c43030523c0f7%2Frf_body_tightening.jpg&w=3840&q=75",
  },
  {
    id: "9b68a3f3-a279-4b47-b857-a34ea20539f5",
    title: "Ultrasound Cavitation",
    url: "https://www.cosmetic.college/courses/beauty/ultrasound-cavitation/",
    contentful_id: "4nuqBWSRkjfoWpZgw9CZWt",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FZsd9SBZGeOuo1IldHAm5I%2Fc47a69b34edb90db53edb8c989623676%2FULTRASOUND-CAVITATION-LASER-TREATMENT-SOUTHEND-ON-SEA-ESSEX-BODY-AESTHETICS-2.jpeg&w=3840&q=75",
  },
  {
    id: "5777217f-4c41-4cea-b809-2a5fdaf6a5ab",
    title: "Lash Lift & Tint",
    url: "https://www.cosmetic.college/courses/beauty/lash-lift-and-tint/",
    contentful_id: "4rJIxg4Q1PW5a7zPLy2mfu",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7cnBdS3bbGRKgE2ofhf8x%2Fa4725b3fe931ecc06caa22e642158ad7%2FIMG_2325.JPG&w=2048&q=75",
  },
  {
    id: "abf7f1e8-4d5e-4b7a-946f-5bb244cfc485",
    title: "Brow Lamination",
    url: "https://www.cosmetic.college/courses/beauty/brow-lamination/",
    contentful_id: "3IwfdDZOSnx2CMnt5n8sWy",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2Fh00yNsDwQGGuLFMIpodEu%2F03f9d254466e032164a8f27cb40a714d%2FImage_from_iOS-25.jpg&w=2048&q=75",
  },
  {
    id: "264345a1-4643-430a-8702-d24ce822437b",
    title: "Face & Body Waxing",
    url: "https://www.cosmetic.college/courses/beauty/waxing/",
    contentful_id: "6ZxDq77L8fEXVZ6XKNdimt",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F54Ozio8nWfZFsbTkIdn3e3%2Ff02b837096e68cd3443ebfcfb75b51c2%2Fwaxing_course_image.jpg&w=2048&q=75",
  },
  {
    id: "9c9b49be-1e52-4fe3-a202-b488dc202b26",
    title: "Microneedling",
    url: "https://www.cosmetic.college/courses/aesthetics/microneedling/",
    contentful_id: "MVCSjhMGPdqn9NmuQTH2V",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F227uAlpBQJNTyLE1HUh0PM%2F2d6d29cf57a340b284cf8e2803db2c81%2FIMG_2277.JPG&w=2048&q=75",
  },
  {
    id: "7f924499-5e7c-4450-ad5a-fc37330d493b",
    title: "Chemical Skin Peels",
    url: "https://www.cosmetic.college/courses/aesthetics/chemical-skin-peels/",
    contentful_id: "5zsbHrKTJmEe0qkPpjbkAK",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2GeMvNf0ZTj7LLlcHPjb2r%2F5f130ab9a8e82966ab4bde5f2712123c%2FScreenshot_2022-05-06_at_07.24.54.png&w=2048&q=75",
  },
  {
    id: "b9b6a23a-2246-4c80-83ac-31c945a8e408",
    title: "Facial And Skincare",
    url: "https://www.cosmetic.college/courses/beauty/facial-and-skincare/",
    contentful_id: "2z0uQjiikvrYMCssvzcTc",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7pemi0ofl1yirLUUkez9AU%2Ff9c529666eddc68f3a5884260965589d%2FIMG_2646.JPG&w=2048&q=75",
  },
  {
    id: "b4999466-03ea-43b3-892d-f868a030d18a",
    title: "Wood Therapy",
    url: "https://www.cosmetic.college/courses/beauty/wood-therapy/",
    contentful_id: "3DZKSSNjbH2xTOYqDaUhxs",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F4BIykRFLSm3xCZJWnjCn6v%2F014c599139c3ea14f36ee816df793c43%2Fwood-therapy.jpg&w=2048&q=75",
  },
  {
    id: "6f342c22-4ae2-4a46-96de-6f8704698958",
    title: "Gel Polish & Dry Manicure",
    url: "https://www.cosmetic.college/courses/beauty/dry-manicure-gel-polish/",
    contentful_id: "7vKN900e6cikYJSmWkBEF4",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F3k2DPTECXahks6z7ImM16W%2F5c8d9f79948211d46f66f0b66b87cd17%2Fgel-nails.jpg&w=2048&q=75",
  },
  {
    id: "45d5e47c-4888-4278-a3fe-0fbf9ed190c3",
    title: "High Definition Brows",
    url: "https://www.cosmetic.college/courses/beauty/high-definition-brows/",
    contentful_id: "1QwH86cybcvUSXv7x9cn03",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FyRBp1eTtNfXBSTURIoqHY%2F77c633793baa11a7802520dc4db33e6d%2FScreenshot_2022-05-13_at_18.11.45.png&w=2048&q=75",
  },
  {
    id: "5ee40e35-4966-423d-9ff8-be115acd398f",
    title: "Intimate Waxing",
    url: "https://www.cosmetic.college/courses/beauty/intimate-waxing/",
    contentful_id: "06TNw8GMxTkQUGF45gOzr",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5Rr0MTSr2ghXNY7l0mYEGs%2Fa8afb31ba765c3d2fc3af3b8120df04b%2Fintimate-waxing-3.jpg&w=2048&q=75",
  },
  {
    id: "de4b5e91-c285-4a65-b7e7-453e19809389",
    title: "LED Light Therapy",
    url: "https://www.cosmetic.college/courses/beauty/led-light-therapy/",
    contentful_id: "5afXlsvdKqg5zv0tXryuKi",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7lWg4V4sLdW62LBq34sShA%2F80a69f0b2ef16d42c3e269215e2b023d%2FIMG_2645.JPG&w=2048&q=75",
  },
  {
    id: "32860f42-cb96-4cd7-99ee-d4f92eb1fddc",
    title: "Individual Classic Eyelash Extensions",
    url: "https://www.cosmetic.college/courses/beauty/classic-eyelash-extensions/",
    contentful_id: "1iAr7hRwvueASdZsHp6Q9Q",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FFPvCZX3aoAOXgiHzU9oQu%2F94b9728385c7f0f3a2cab3eca9e456e6%2FIMG_2327.JPG&w=2048&q=75",
  },
  {
    id: "331f350a-c561-4327-85ee-1f20689e1c3e",
    title: "Plasma Fibroblast Skin Tightening",
    url: "https://www.cosmetic.college/courses/aesthetics/plasma-fibroblast-skin-tightening/",
    contentful_id: "3A7cAfReqYwEbPa3XMAC9E",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F3bfWR9RwOYh34ZTpjmrZy%2Fe9c3a62cad8db6d26ad390457c4539af%2FIMG_2288.JPG&w=2048&q=75",
  },
  {
    id: "996a9163-ca62-475b-8ba9-843abf40bcb8",
    title: "Russian Lash Extensions",
    url: "https://www.cosmetic.college/courses/beauty/russian-lash-extensions/",
    contentful_id: "6LAwbkO2b1fawoUHpPdd1O",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1BXztkaAdMAVGlgIFjKu2X%2F4291b0827bbc9f162951d85b6c2b4a1f%2FIMG_2606_2.JPG&w=2048&q=75",
  },
  {
    id: "2acf5c13-4c6f-4d24-b2a4-c215d46b6e8c",
    title: "Dermaplaning",
    url: "https://www.cosmetic.college/courses/beauty/dermaplaning/",
    contentful_id: "1SK1G16xUC8JSWl5ZAQVXd",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7amdNmvpxQqiBH7JzhZaq1%2F8627c027865fbf47bb9c54ee35f0176b%2FIMG_2609.JPG&w=2048&q=75",
  },
  {
    id: "9df8cadd-d527-4210-9fc4-d6783ee3a911",
    title: "Face HiFu",
    url: "https://www.cosmetic.college/courses/beauty/face-hifu/",
    contentful_id: "5PFSNJ2SPeeNIX4wUgzBg7",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FywmyIeAsLAMDiNRWtpD6A%2F7b828619213c8a16837a1017a217b103%2FHIFU_treatment.png&w=2048&q=75",
  },
  {
    id: "de89fdb3-a5c5-41a5-bb86-c2ca1ea5e4f6",
    title: "Body HiFu",
    url: "https://www.cosmetic.college/courses/beauty/body-hifu-training/",
    contentful_id: "3plE8jZ7rbW2qvJtdaXJsQ",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2IpDLixKf8GU6LhU9m2NLr%2Fa359a42e453fd370947753f8ee82eade%2FHIFU-treatment-body-contouring.png&w=2048&q=75",
  },
  {
    id: "e7a1a953-6ad8-4add-bd79-26d1555c8ef4",
    title: "Hydradermabrasion",
    url: "https://www.cosmetic.college/courses/beauty/hydra-dermabrasion-facials/",
    contentful_id: "1KLoVdDS2EyxDeapgfX8e9",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F3Bay6IbDkdbbJNHsfW9U8u%2F522815fea71240ea618c30430e3d404b%2FIMG_2282.JPG&w=2048&q=75",
  },
  {
    id: "20e39d7f-9ff6-4138-a2a8-7ec8822d2b66",
    title: "Foundation Anti Wrinkle Injections",
    url: "https://www.cosmetic.college/courses/aesthetics/foundation-anti-wrinkle-injections-course/",
    contentful_id: "2k54aNHI2bYAJrdgjo1JyW",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1fWdkzoML0WuGEDsYG3cxq%2F347e199f55602de6a61782f703fbce7a%2FIMG_2655.JPG&w=2048&q=75",
  },
  {
    id: "caa0fed9-f7f6-4025-9952-f53bc605d6d9",
    title: "Lemon Bottle Fat Dissolving",
    url: "https://www.cosmetic.college/courses/aesthetics/lemon-bottle-fat-dissolving/",
    contentful_id: "4vK2hn0kplJNLsgXuu9RRZ",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6cpxSsLwnCOhcFqX0a2mnr%2F4b7dceb7640927a0a7ef4f6b35150dd8%2F1689189373256-Lemon-Bottle-Injections-Charmelle-London.jpg&w=3840&q=75",
  },
  {
    id: "2d8444e3-ccea-49a7-8143-a660efb42b46",
    title: "VTCT NVQ Level 3 In Beauty Therapy",
    url: "https://www.cosmetic.college/courses/vtct/level-3-nvq-diploma-in-beauty-therapy-general/",
    contentful_id: "1WXQt4LiYmwTHtr73VCmkQ",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6QTmqjtp5lY3h1PsqpW7SO%2F694efffb7af22fabc2f013345f51bc63%2FBeauty-Therapy.lvel_3.jpg&w=2048&q=75",
  },
  {
    id: "2d8dfa48-2295-4027-b151-f512de4b3e77",
    title: "VTCT Level 4 Skin Microneedling Therapy",
    url: "https://www.cosmetic.college/courses/vtct/level-4-in-skin-microneedling/",
    contentful_id: "7m6zk4tuCFu3awTGAhhKfr",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6k2hsxx01vnC0lCOPlTJb%2Fb8dcbe7022573d3d94f186c14a1b6e9a%2FUntitled_design__14_.png&w=2048&q=75",
  },
  {
    id: "4ba8c653-c5ec-4ef6-9e10-dd584e83ee96",
    title: "VTCT Level 4 Radio Frequency",
    url: "https://www.cosmetic.college/courses/vtct/level-4-radio-frequency/",
    contentful_id: "3g1uBeizk5tdoTfMCd9Zws",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F4tEWsSib7WmZCtFMsvGVNr%2F853e901f71200ef6546f4353c31fe328%2FUntitled_design__6_.png&w=2048&q=75",
  },
  {
    id: "8d5ce27d-c9e1-45dd-9cef-3869c2e065d7",
    title: "VTCT Level 4 In Ultrasound",
    url: "https://www.cosmetic.college/courses/vtct/level-4-ultrasound/",
    contentful_id: "3N7s6PSs743cXJvR1WgC5",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FZsd9SBZGeOuo1IldHAm5I%2Fc47a69b34edb90db53edb8c989623676%2FULTRASOUND-CAVITATION-LASER-TREATMENT-SOUTHEND-ON-SEA-ESSEX-BODY-AESTHETICS-2.jpeg&w=2048&q=75",
  },
  {
    id: "15765417-1845-4802-aac2-4c7625561270",
    title: "VTCT Level 4 Diploma in Advanced Beauty Therapy",
    url: "https://www.cosmetic.college/courses/vtct/level-4-diploma-advanced-beauty-therapy/",
    contentful_id: "6HbAYBlJNxpycz1H8KO1cx",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F4PSQw7oszSYh2ez0FsRrYF%2F8e7fb2f078330bf4066cf3412ab0bfe3%2FUntitled-design-9.png&w=2048&q=75",
  },
  {
    id: "a38e4abf-b323-405a-acf0-04722d59be69",
    title: "VTCT Level 4 Chemical Skin Peels",
    url: "https://www.cosmetic.college/courses/vtct/level-4-chemical-skin-peels/",
    contentful_id: "1wEt2OjxkbXXk4zbkIHUzt",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F4CidQM4cKtT04lbvoBHpGZ%2F2ac37802f6cf584cea771172d6c4f29f%2FGettyImages-937239886_thumb.jpg&w=2048&q=75",
  },
  {
    id: "75485b72-c0c1-46cb-938a-63d83ecee7db",
    title: "VTCT Level 4 Certificate In Micropigmentation",
    url: "https://www.cosmetic.college/courses/vtct/level-4-certificate-in-micropigmentation/",
    contentful_id: "3C2fRaQRiwMjA3Inq6UHqK",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F55dzmvNLOk5pkT5gbLAAat%2Feca5e6843b885c4143f10b3785094353%2FIMG_2285.JPG&w=2048&q=75",
  },
  {
    id: "a9c9192b-249f-4bb8-8db9-d5ba6614d050",
    title:
      "VTCT Level 4 Certificate in Enhancing Eyebrows with Microblading Techniques",
    url: "https://www.cosmetic.college/courses/vtct/level-4-certificate-in-enhancing-eyebrows-with-microblading-techniques/",
    contentful_id: "2D6Xd7ARoNFR8qaqRl7FM2",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F4hZJhJjehKNLYPlkd6sCVG%2F567909919e38f69ce86a623ff11b16ce%2Fmicroblade-blog_bec46dae-929b-4266-8579-b670ce6927b6_1000x.jpg&w=2048&q=75",
  },
  {
    id: "7ae9315c-22ff-414e-8381-d29100357483",
    title: "VTCT Level 2 Facial & Skincare",
    url: "https://www.cosmetic.college/courses/vtct/level-2-facial-and-skincare-course/",
    contentful_id: "6VTg0QoePY3USAUaphKflM",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F3BTAPcoo9vGqkppjahUgSG%2F521e74d56c5558115588075435308c4d%2FUntitled_design__4_.png&w=2048&q=75",
  },
  {
    id: "ab170dd8-6132-4873-9392-f9cf87f114c0",
    title: "VTCT Laser Hair Removal",
    url: "https://www.cosmetic.college/courses/vtct/laser-hair-removal/",
    contentful_id: "5MrEz8xx8s0YFtNHRCes3i",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F3nLUetAYUdpgxTr7ls5FZL%2Fee8f832faf9969b0f8c6da049ac300d8%2FUntitled_design__5_.png&w=2048&q=75",
  },
  {
    id: "154b7122-58d5-47a5-b08d-3cada6532aaa",
    title: "Foundation Dermal Fillers",
    url: "https://www.cosmetic.college/courses/aesthetics/foundation-dermal-fillers/",
    contentful_id: "38cwi3gDzvcD9mrlIHMOvD",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F17DOtzUlXWU6WmUges3DZP%2Fd2ff7bd72d232b78e4e4f4c02879279c%2FIMG_2603_2.JPG&w=2048&q=75",
  },
  {
    id: "3dda54aa-444b-4065-9294-3bbbc19b0cfc",
    title: "Foundation Lip Dermal Fillers",
    url: "https://www.cosmetic.college/courses/aesthetics/lip-dermal-filler-course/",
    contentful_id: "21JXr7i11XFH0B5C7MReyp",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5TC0ywzfmbc3zg3Mm2DYwe%2F7f3462c5a8c186ecf4d008c17c5c436c%2FCD144E7C-85A9-4D1C-BA85-E7E4F4BB94F7.JPG&w=2048&q=75",
  },
  {
    id: "1f46024c-7bef-40e1-8866-ee893c5a287f",
    title: "Dermal Fillers and Anti-Wrinkle Package",
    url: "https://www.cosmetic.college/courses/packages/dermal-fillers-and-anti-wrinkle-package/",
    contentful_id: "32hEfiCzA35ii8gfDjdjIt",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FpA8RRlOM4Y4Anumwa2OBn%2F0416cec5961870e769709083c6065385%2FC6E6A85F-F967-4A9B-A7EF-863B338427EE.JPG&w=2048&q=75",
  },
  {
    id: "9b4d5646-eec6-43b6-be74-6c100fdc6308",
    title: "Russian Lip Enhancement Masterclass",
    url: "https://www.cosmetic.college/courses/aesthetics/russian-lip-enhancement-masterclass/",
    contentful_id: "4LbD8KNm8fMGKwjAE0Q6lu",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1B73JSPIICERP2TgAxY91Z%2F7937306a8217b021414bc65fb6bd2b8b%2FScreenshot_2021-02-28_at_07.45.13.png&w=2048&q=75",
  },
  {
    id: "c550ca68-f9e3-4407-9261-656f602c291d",
    title: "PRP Vampire Facial",
    url: "https://www.cosmetic.college/courses/aesthetics/prp-vampire-facial/",
    contentful_id: "5yEYTNKTUmqNNj21DAxeeX",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5FyxZvuSVTTNdS0hNhUHBD%2F0b912d62ad27dafadf1bab51c9603ea3%2Fprp-vampire-facial.jpg&w=2048&q=75",
  },
  {
    id: "79604da6-4bde-40d9-97b9-2d022fe221dc",
    title: "Vitamin B12 Injections",
    url: "https://www.cosmetic.college/courses/aesthetics/vitamin-b12-injections/",
    contentful_id: "3qLThLmaU5lWR63ivKHIg7",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1ykf5A11d1pYlfnP6vOXIW%2F3e14b26ef5a4d1b791e671d5798ed39d%2FIMG_2648.JPG&w=2048&q=75",
  },
  {
    id: "dc420aad-4faf-4165-b514-725249aeb605",
    title: "Phlebotomy",
    url: "https://www.cosmetic.college/courses/aesthetics/phlebotomy/",
    contentful_id: "1OEuS45O6mchuXNLsfidYn",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5dBbWxdOWM9dMdOPanBBRx%2F8f70fab27c1b36b22b168e6347865999%2FIMG_2605_2.JPG&w=2048&q=75",
  },
  {
    id: "99e1e043-abdc-493d-8fd0-2ec24d3b2bee",
    title: "BB Glow Foundation",
    url: "https://www.cosmetic.college/courses/semi-permanent-makeup/bb-glow/",
    contentful_id: "4MK5Uavvd0BUCqaz03xuB5",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1msnhcI97EWvwA5FHlYMlj%2F28fda10e10176aa2c778f2197ade6675%2FIMG_2354.JPG&w=2048&q=75",
  },
  {
    id: "344c2203-22ec-4a4e-ba81-690c36862b07",
    title: "Tear Trough & Non-Surgical Rhinoplasty Masterclass",
    url: "https://www.cosmetic.college/courses/aesthetics/tear-trough-masterclass/",
    contentful_id: "tW3wogRfBWWlFvJwErklH",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FoesUsrXno9GsH2fz58U3b%2F4f7a10772e7549e943280c07aad5935f%2Ftear_t.png&w=2048&q=75",
  },
  {
    id: "80d8ffa3-d181-4ac7-bd6f-507ddd7faa03",
    title: "Cheeks, Jawline & Chin Dermal Fillers",
    url: "https://www.cosmetic.college/courses/aesthetics/jawline-dermal-fillers-masterclass/",
    contentful_id: "44lVe6JTh8xv3rnLOtzU4J",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F62MVX0hFBSEssx4ZkWb5GS%2Fd37304a9939a4266994a0908ebb5af3a%2FIMG_0458.jpg&w=2048&q=75",
  },
  {
    id: "a1d98a16-737c-4e3d-ad9e-1f4c72e9a023",
    title: "Buttock Augmentation",
    url: "https://www.cosmetic.college/courses/aesthetics/buttock-augmentation/",
    contentful_id: "6WHBqSwRbvEOSfSihJB2ox",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6MMj9zFSOWCx1125pLUCQ%2Ff9112f6decefde03527f217b2693dd53%2Fbrazilian-butt-lift-fat-grafting.jpeg&w=2048&q=75",
  },
  {
    id: "3456c162-bc9a-4331-a7a2-ff595b0ced77",
    title: "Aqualyx Fat Dissolving",
    url: "https://www.cosmetic.college/courses/aesthetics/aqualyx-fat-dissolving/",
    contentful_id: "4LVd3KgqBQ43prSvHXJSSz",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6G3rJcoBDMaPmjm9VPurJo%2F218b2708a42d4e54bc3fa4aefece622a%2FIMG_2290.jpg&w=2048&q=75",
  },
  {
    id: "5ede21bb-5e0d-46a8-8cdd-9edc87509077",
    title: "Lidocaine Infusion",
    url: "https://www.cosmetic.college/courses/aesthetics/lidocaine-infusion-training-course/",
    contentful_id: "5s5LPmOBSuVxbEEFZE4R8V",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6Dn2aaY8EXxzSzsB9VG7lB%2Feaa3a2e48a78abf551ffb03ae43096dc%2Fdental-block.jpg.webp&w=2048&q=75",
  },
  {
    id: "bbfced9b-c09f-4e0c-89ff-8a6edf328810",
    title: "Ombre Brows",
    url: "https://www.cosmetic.college/courses/semi-permanent-makeup/ombre-brows/",
    contentful_id: "7k6tzCaKfzdZBInwiptfpS",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2JSq9yNyobEFxQfHuJhatg%2Fc3bdaefdeeeb929b13045aa3cf8b8997%2FIMG_2276.JPG&w=2048&q=75",
  },
  {
    id: "5ef13fe0-9f92-477c-b517-2a9f2544e0f7",
    title: "Lip Blush",
    url: "https://www.cosmetic.college/courses/semi-permanent-makeup/lip-blush-training/",
    contentful_id: "7lwhKEjHB8yfL0lRukNZmx",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6dmeHnGRqVCcwzBlEZWBnJ%2F1f901f6140945f05420bf1f4efffbec5%2FIMG_2643.JPG&w=2048&q=75",
  },
  {
    id: "cd4d2c3a-6f6e-4f73-af49-80ac52809728",
    title: "Permanent Makeup Saline Removal & Correction",
    url: "https://www.cosmetic.college/courses/semi-permanent-makeup/semi-permanent-makeup-saline-removal/",
    contentful_id: "Gty6IG6IQiEVhhcwJgeqz",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5coHuLOtEihUTBDJSMD9Vv%2F5819f3bf27b3f5e4b9af3f26942d4e9e%2FIMG_2286.JPG&w=2048&q=75",
  },
  {
    id: "098def47-aef9-4430-abf3-de89ac3fe870",
    title: "Full Face Permanent Makeup",
    url: "https://www.cosmetic.college/courses/semi-permanent-makeup/ultimate-permanent-makeup/",
    contentful_id: "7uqXSMraDGmyvE3jrLq8rJ",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6YyXfKZ93vd5Hf6fvzkLgu%2F697a78244deec636bc38a632e5238dab%2FIMG_2307.JPG&w=2048&q=75",
  },
  {
    id: "065c040f-3508-4589-8bb7-d9c8c5ffe0f7",
    title: "Scalp Micropigmentation",
    url: "https://www.cosmetic.college/courses/semi-permanent-makeup/scalp-micropigmentation/",
    contentful_id: "6AkTamsyeFInPa3eIGShOH",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F12l1EXcUvhfw7RjONoP6X6%2Fec2f82b9c4ae0ca6def50d6b897aa79a%2Fscalp-micropigmentation3-1024x682.jpg&w=2048&q=75",
  },
  {
    id: "bd0e4d7a-8285-4c8f-b5cb-51a552547426",
    title: "Microblading",
    url: "https://www.cosmetic.college/courses/semi-permanent-makeup/microblading/",
    contentful_id: "1JVRyfuE5h4pMmiDBo8kEd",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2Xw4v6OoaecADVUno6dniD%2F5463f7cc747572ee7283d53a70996647%2FIMG_2283.JPG&w=2048&q=75",
  },
  {
    id: "f8e48e80-b3e8-4457-9b80-85b70adf82a6",
    title: "Eyeliner & Lash Enhancements",
    url: "https://www.cosmetic.college/courses/semi-permanent-makeup/eyeliner-and-lash-enhancements/",
    contentful_id: "4VQ9DYmEYM5kMfdAcrd7Nu",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F55dzmvNLOk5pkT5gbLAAat%2Feca5e6843b885c4143f10b3785094353%2FIMG_2285.JPG&w=2048&q=75",
  },
  {
    id: "666e0fee-b9fc-4665-922d-1f4a187e4cf2",
    title: "Face & Hair Rejuvenation Package",
    url: "https://www.cosmetic.college/courses/packages/face-and-hair-rejuvenation-package/",
    contentful_id: "1OQn5fwUaoh6cOoyyZbho3",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5FyxZvuSVTTNdS0hNhUHBD%2F0b912d62ad27dafadf1bab51c9603ea3%2Fprp-vampire-facial.jpg&w=3840&q=75",
  },
  {
    id: "9623c307-f2e9-4a67-baa7-3efbe4458c09",
    title: "Body Sculpting Training Package",
    url: "https://www.cosmetic.college/courses/packages/body-sculpting-training-package/",
    contentful_id: "2b36hhkiLtKjiJwDVeKsKq",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F40IspLtEvZba6EKdAvwGkx%2F7b832874b6dd4220438fe6a40b0ab461%2FIMG_2290.jpg&w=3840&q=75",
  },
  {
    id: "907a9a3f-702a-4859-9749-2a4c15e14309",
    title: "3D Hairstroke Brows Training Course",
    secondaryTitle: "Digital Hair Stroke Eyebrows Masterclass",
    url: "https://www.cosmetic.college/courses/semi-permanent-makeup/3d-hairstroke-brows/",
    contentful_id: "6rDcoUsdZDDPSRIPcovoPE",
    source:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F4AejbeHWARPrVmVEyEbjlk%2Fe91373477bb396afa5efeb81f3c9ab0a%2FScreenshot_2023-01-28_at_07.50.21.png&w=3840&q=75",
  },
];

//0df38dfd-3a9e-4514-8e04-54afd21b4574
//6119b3e9-7077-47df-b2e6-62162d5addf3

export const links = [
  {
    link: "/courses",
    text: "Training Courses",
    children: [
      {
        link: "/courses/vtct/",
        text: "VTCT Training Courses",
        description:
          "High quality, fast track, nationally recognised training courses",
      },
      {
        link: "/courses/masterclasses/",
        text: "Masterclasses",
        description: "Focused in-depth masterclass training courses",
      },
      {
        link: "/courses/aesthetics/",
        text: "Aesthetics",
        description:
          "Take your career to the next with training courses to become an aesthetic practitioner",
      },
      {
        link: "/courses/injectables/",
        text: "Injectables",
        description:
          "Take your career to the next with training courses in injectables",
      },
      {
        link: "/courses/semi-permanent-makeup/",
        text: "Semi Permanent Makeup Training",
        description:
          "Professional permanent makeup training & products from the Cosmetic College",
      },
      {
        link: "/courses/packages/",
        text: "Training Packages",
        description:
          "Combination training courses to maximise your capabilities",
      },
      {
        link: "/courses/elearning/",
        text: "Online Training Courses",
        description:
          "Fully online e-learning training courses delivered by our team of expert tutors",
      },
      {
        link: "/courses/beauty/",
        text: "Beauty",
        description: "Get qualified through expert led beauty training courses",
      },
      {
        link: "/courses/education/",
        text: "Education Training",
        description:
          "Launch a new career as a tutor or assessor with our education training courses",
      },
      {
        link: "/training-course-calendar/",
        text: "Course Calendar",
        description:
          "Browse all available training dates with our helpful calendar",
      },
    ],
    additional: [
      {
        id: 986276627,
        title: "Level 7 Certificate In Aesthetic Practice",
        href: "https://www.cosmetic.college/courses/aesthetics/level-7-certificate-in-aesthetic-practice/",
        date: "New!",
        datetime: "2024-02-14",
        category: {
          title: "Aesthetics",
          href: "https://www.cosmetic.college/courses/aesthetics/",
        },
        imageUrl:
          "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1n4DrvU6zz7HgldoFZgzOm%2F7560b75736122409a47ea22dbaaeb7f0%2FUntitled_design__3_.png&w=3840&q=75",
        description:
          "Brand new at the Cosmetic College. Sign up now to secure your place ahead of industry regulation changes",
      },
      {
        id: 27681687268,
        title: "New Training Courses",
        href: "https://www.cosmetic.college/courses/new",
        date: "New",
        datetime: "2023-02-15",
        category: {
          title: "Courses",
          href: "https://www.cosmetic.college/courses/new",
        },
        imageUrl:
          "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2Frme79xhtCVRI6Vic9YCHt%2Fa09216db19a6f89b313becd88d7fc82a%2FUntitled_design.png&w=3840&q=75",
        description:
          "View our latest new courses to keep up to date with the latest industry trends and regulations",
      },
    ],
  },
  {
    link: "/courses/masterclasses/",
    text: "Masterclasses",
    mobile: false,
    description: "Focused in-depth masterclass training courses",
  },
  {
    link: "/training-course-calendar/",
    text: "Course Calendar",
    mobile: false,
    description:
      "Browse all available training dates with our helpful calendar",
  },
  // {
  //   link: "/courses/elearning/",
  //   text: "Who We Train",
  //   mobile: false,
  //   description:
  //     "View available courses based on your current professional level",
  //   children: [
  //     {
  //       link: "/courses/vtct/",
  //       text: "VTCT Training Courses",
  //       description:
  //         "High quality, fast track, nationally recognised training courses",
  //     },
  //   ],
  //   additional: [
  //     {
  //       id: 986276627,
  //       title: "Level 7 Certificate In Aesthetic Practice",
  //       href: "https://www.cosmetic.college/courses/aesthetics/level-7-certificate-in-aesthetic-practice/",
  //       date: "New!",
  //       datetime: "2024-02-14",
  //       category: {
  //         title: "Aesthetics",
  //         href: "https://www.cosmetic.college/courses/aesthetics/",
  //       },
  //       imageUrl:
  //         "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1n4DrvU6zz7HgldoFZgzOm%2F7560b75736122409a47ea22dbaaeb7f0%2FUntitled_design__3_.png&w=3840&q=75",
  //       description:
  //         "Brand new at the Cosmetic College. Sign up now to secure your place ahead of industry regulation changes",
  //     },
  //     {
  //       id: 27681687268,
  //       title: "New Training Courses",
  //       href: "https://www.cosmetic.college/courses/new",
  //       date: "New",
  //       datetime: "2023-02-15",
  //       category: {
  //         title: "Courses",
  //         href: "https://www.cosmetic.college/courses/new",
  //       },
  //       imageUrl:
  //         "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2Frme79xhtCVRI6Vic9YCHt%2Fa09216db19a6f89b313becd88d7fc82a%2FUntitled_design.png&w=3840&q=75",
  //       description:
  //         "View our latest new courses to keep up to date with the latest industry trends and regulations",
  //     },
  //   ],
  // },
  // {
  //   link: "/courses/elearning/",
  //   text: "Online Training Courses",
  //   mobile: false,
  //   description:
  //     "Fully online e-learning training courses delivered by our team of expert tutors",
  // },
  // {
  //   link: "/training-course-calendar/",
  //   text: "Training Calendar",
  //   mobile: false,
  //   description:
  //     "Browse available training course dates and book your place today",
  // },
  // {
  //   link: "/courses/aesthetics/fast-track-to-aesthetics/",
  //   text: "Fast Track To Aesthetics",
  //   mobile: false,
  //   description: "Launch a new career with our accredited insurable pathway",
  // },
  {
    link: "/models",
    text: "Models",
    mobile: false,
    // mobile: "Book A Model Session",
  },
  {
    link: "/for-business",
    text: "For Employers",
    mobile: false,
  },
  {
    link: "/courses",
    text: "More",
    grid: true,
    children: [
      {
        link: "/training-course-calendar/",
        text: "Training Calendar",
      },
      {
        link: "/contact",
        text: "Contact Us",
      },
      {
        link: "/blog",
        text: "Our Blog",
      },
      {
        link: "/about",
        text: "About Us",
      },
      {
        link: "/finance",
        text: "Finance",
      },
      {
        link: "/careers",
        text: "Join The Team",
      },
      {
        link: "/models",
        text: "Book Model Session",
      },
      {
        link: "/for-business",
        text: "For Employers",
      },
    ],
  },
];
