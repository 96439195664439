
export enum Conversion {
  'contact' = 'AW-418666221/I_cJCKDt8_UBEO2t0ccB',
  'newsletter' = 'AW-418666221/hjzACIzB1PUBEO2t0ccB',
  'signup' = 'AW-418666221/uDvwCNis5_UBEO2t0ccB',
  'email' = 'AW-418666221/aiGICJ-15_UBEO2t0ccB',
  'enrol' = 'AW-418666221/pvANCLvT-vYBEO2t0ccB',
  'callback' = 'AW-418666221/fOcNCMuOoPkBEO2t0ccB'
}

export function recordConversion (params: Gtag.ControlParams) {
  if (window && window.gtag) {
    gtag('event', 'conversion', params);

    (window as any).heap &&
        (window as any).heap.addUserProperties({
          type: 'conversion',
          ...params,
        });
  }
}

export const track = (action, params, fields?) => {
  window.gtag && window.gtag('event', action, {
    ...params
  })
  const h: { [key: string]: any } = {
    page: window.location.href
  }
  if (params.event_label) {
    h.label = params.event_label
  }
  // @ts-ignore
  window.heap && window.heap.track(action, h)
  if (fields) {
    // @ts-ignore
    window.heap && window.heap.addUserProperties({ ...fields })
  }
}
