// @ts-nocheck
import * as Sentry from "@sentry/nextjs";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import Link from "next/link";
import * as yup from "yup";
const { yupResolver } = require("@hookform/resolvers/yup");
import format from "date-fns/format";
import { XCircleIcon } from "@heroicons/react/24/outline";
import cookies from "js-cookie";

import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { loadStandardContent } from "utils/api";
import { useSession } from "components/providers/session";
import Layout from "components/layout";
import getStripe from "utils/stripe";
import { klayAction } from "utils/klay";
import { recordConversion, Conversion } from "utils/conversions";

export const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

const lowestFree = false;

const schema = yup.object().shape({
  selected: yup.string(),
  name: yup.string().when("selected", (a, schema) => {
    return a === "card" ? schema.required() : schema.optional();
  }),
  surname: yup.string().when("selected", (a, schema) => {
    return a === "card" ? schema.required() : schema.optional();
  }),
  email: yup.string().when("selected", (a, schema) => {
    return a === "card" ? schema.email().required() : schema.optional();
  }),
  phone: yup.string().when("selected", (a, schema) => {
    return a === "card" ? schema.required() : schema.optional();
  }),
  courses: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          title: yup.string(),
          price: yup.number(),
          wasPrice: yup.number(),
          date: yup.string().nullable(true),
          includeKit: yup.string(),
          freeKit: yup.boolean().default(false),
          kitPrice: yup.number(),
          kitName: yup.string(),
          kitWorthPrice: yup.number(),
        })
        .required()
    )
    .min(1),
});

function EnrollCmp({
  session,
  discount,
  freeDiscount,
  setState: setParentState,
  fee,
  total,
  fullTotal,
  deposit,
  orderId,
  form,
  mode,
  thirdParty,
  freeEligible,
  currentCourses,
  currentValues,
  courseFields,
  changeMode,
  createOrder,
  updateOrder,
}) {
  const elements = useElements();
  const stripe = useStripe();
  const [state, setState] = useState({
    paymentElement: null,
    finance: false,
    financeDone: false,
  });
  const { fields, remove } = courseFields;
  const [klaviyoEvent, setKlaviyoEvent] = useState(false);
  const [klayEventId, setEventId] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params) {
      const status = params.get("status");
      if (status && status === "failed") {
        setState((s) => ({
          ...s,
          error: "Payment failed. Please try an alternative payment method",
        }));
      }
    }
  }, []);

  useEffect(() => {
    try {
      klaviyo &&
        klaviyo.push([
          "identify",
          {
            $email: currentValues.email,
            $first_name: currentValues.name,
            $last_name: currentValues.surname,
            $phone_number: currentValues.phone,
          },
        ]);
    } catch (err) {
      console.log(err);
    }
  }, [currentValues]);

  useEffect(() => {
    if (session.selectedCourses.length > 0 && !klaviyoEvent) {
      try {
        const items = session.selectedCourses.map((x) => klayAction(x));
        const eventId =
          session.user && session.user.attributes
            ? `${session.user.attributes.sub.substring(0, 7)}_${Date.now()}`
            : `${Math.floor(1000000 + Math.random() * 900000)}_${Date.now()}`;

        setEventId(eventId);

        const payload = {
          $event_id: eventId,
          $value: session.selectedCourses.reduce(
            (a, x) =>
              a +
              (x.package
                ? x.event.standardPrice / 100
                : x.selectedEvent
                ? x.selectedEvent.price / 100
                : x.event.standardPrice / 100),
            0
          ),
          ItemNames: items.map((x) => x.ProductName),
          CheckoutURL: "https://www.cosmetic.college/enroll/",
          Categories: items.map((p) => p.Categories).flat(),
          Items: items,
        };
        setKlaviyoEvent(true);
        // @ts-ignore
        klaviyo && klaviyo.push(["track", "Started Checkout", payload]);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
      }
    }
  }, [session.selectedCourses]);

  useEffect(() => {
    if (!state.paymentElement && elements) {
      const paymentElement = elements.getElement("payment");
      if (paymentElement) {
        paymentElement.on("change", (event) => {
          changeMode(
            event.value.type === "klarna"
              ? "full"
              : event.value.type === "afterpay_clearpay"
              ? "full"
              : "fee",
            event.value.type === "klarna" ||
              event.value.type === "afterpay_clearpay"
          );
          try {
            window.gtag &&
              window.gtag("event", "set_checkout_option", {
                checkout_step: 3,
                checkout_option: "payment method",
                value:
                  event.value.type === "klarna"
                    ? "klarna"
                    : event.value.type === "afterpay_clearpay"
                    ? "afterpay"
                    : "card",
              });
          } catch (err) {
            console.log(err);
            Sentry.captureException(err);
          }
        });
        setState((s) => ({ ...s, paymentElement }));
      }
    }
  }, [elements]);

  const payBookingFee = async () => {
    const newMode = await changeMode("fee");
    form.handleSubmit((e) =>
      onSubmit({
        ...e,
        mode: "fee",
      })
    )();
  };

  const payInFull = async () => {
    const newMode = await changeMode("full");
    form.handleSubmit((e) =>
      onSubmit({
        ...e,
        mode: "full",
      })
    )();
  };

  const onSubmit = async (data) => {
    try {
      // @ts-ignore
      klaviyo &&
        klaviyo.push([
          "identify",
          {
            $email: data.email,
            $first_name: data.name,
            $last_name: data.surname,
            $phone_number: data.phone,
          },
        ]);

      const items = session.selectedCourses.map((x) => klayAction(x));

      // @ts-ignore
      klaviyo &&
        klaviyo.push([
          "track",
          "Attempted payment",
          {
            $email: data.email,
            $payment_method: data.selected,
            Items: items,
            eventId: klayEventId,
          },
        ]);

      try {
        const t = getPrices(currentValues);
        window.gtag &&
          window.gtag("event", "add_payment_info", {
            currency: "GBP",
            value: t.total / 100,
            items: session.selectedCourses.map((x, i) => {
              return {
                item_id: x.event.id,
                item_name: x.fields.title,
                affiliation: "Cosmetic College",
                currency: "GBP",
                index: i,
                item_brand: "Cosmetic College",
                item_category: "Courses",
                item_category2: x.fields.categories[0],
                item_list_id: x.fields.categories[0],
                item_list_name: capitalize(x.fields.categories[0]),
                price: x.package
                  ? x.event.standardPrice / 100
                  : x.selectedEvent.price / 100,
                quantity: 1,
              };
            }),
          });
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
      }

      const events = fields.map((field) => {
        return field.selectedEvent
          ? field.selectedEvent
          : {
              id: field.event.id,
              created_at: new Date(),
              updated_at: new Date(),
              price: field.event.standardPrice,
              seat_price: field.event.standardPrice,
              seats: 0,
              type: "onsite",
              start: new Date(),
              end: new Date(),
              date: new Date(),
              detail: {
                title: field.fields.title,
                is_package: field.event.package,
                e_learning_course_id: null,
                id: field.event.id,
              },
              status: "NEW",
              detail_id: field.event.id,
              registrations: [],
              countStudentRegistrations: {
                aggregate: {
                  count: 0,
                },
              },
            };
      });

      const enrollment = {
        discount: discount,
        enrollment: {
          balance: total,
          metadata: events,
          deposit: data.mode === "fee" ? deposit : 0,
          deposit_required: data.mode === "fee",
          total: fullTotal,
          method: "stripe",
          status: "pending",
          type: "student",
          organization: process.env.NEXT_PUBLIC_ORGANIZATION_ID,
        },
      };

      setState((s) => ({ ...s, loading: true, error: false }));

      let newOrderId = orderId;

      newOrderId = await createOrder(enrollment);

      const currentMode = data.mode === "full";

      const intentRes = await updateIntent(
        fee.id,
        currentMode ? fullTotal : deposit,
        undefined,
        data.email,
        {
          "Order ID": newOrderId.id || newOrderId,
          name: data.name,
          surname: data.surname,
          phone: data.phone,
          email: data.email,
          type: currentMode ? "full-amount" : "booking-fee",
        }
      );

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url:
            process.env.NODE_ENV === "development"
              ? `http://localhost:3000/enrolment/${newOrderId.id || newOrderId}`
              : `https://www.cosmetic.college/enrolment/${
                  newOrderId.id || newOrderId
                }`,
        },
      });

      if (error && error.message) {
        setState((s) => ({
          ...s,
          error: `Payment failed ${error.message}`,
          loading: false,
        }));
        throw new Error(error.message);
      }

      setState((s) => ({ ...s, success: true, error: null, loading: false }));
    } catch (err) {
      Sentry.captureException(err);
      (window as any).heap &&
        (window as any).heap.track("Booking Error", {
          method: "Enrol Error",
          ...err,
        });
      console.log(err);
      setState((s) => ({
        ...s,
        error: `Payment failed ${err.message}`,
        loading: false,
      }));
    }
  };

  const financeForm = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    },
  });

  const financeSubmit = async (data) => {
    try {
      setState((s) => ({ ...s, error: null }));
      const req = await fetch("/api/callback/", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...data,
          courses: currentCourses,
          type: "finance",
        }),
      });

      const res = await req.json();

      if (res && res.success) {
        setState({
          finance: true,
          financeDone: true,
        });

        recordConversion({
          send_to: Conversion.callback,
        });

        (window as any).fbq && (window as any).fbq("track", "Contact");

        (window as any).heap &&
          (window as any).heap.addUserProperties({
            ...data,
          });

        (window as any).heap &&
          (window as any).heap.track("Finance Request", {
            ...data,
          });

        // @ts-ignore
        klaviyo &&
          klaviyo.push([
            "identify",
            {
              $email: data.email,
              $first_name: data.first_name,
              $last_name: data.last_name,
              $phone_number: data.phone_number,
            },
          ]);

        // @ts-ignore
        klaviyo &&
          klaviyo.push([
            "track",
            "Finance Request",
            {
              $email: data.email,
              $first_name: data.first_name,
              $last_name: data.last_name,
              $phone_number: data.phone_number,
              $path_name: window.location.pathname,
            },
          ]);
      } else {
        setState((s) => ({
          ...s,
          error: "Error processing request, Please try again",
        }));
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      setState((s) => ({
        ...s,
        error: "Error processing request, Please try again",
      }));
    }
  };

  const checkDiscountCode = async () => {
    const values = form.getValues();
    if (!values || !values.discount || values.discount.length === 0) {
      setParentState((s) => ({ ...s, discount: { missing: true } }));
    }

    setParentState((s) => ({ ...s, discount: { loading: true } }));

    try {
      const req = await fetch("/api/code", {
        method: "post",
        body: JSON.stringify({
          code: values.discount,
        }),
      });

      if (req.ok) {
        const res = await req.json();
        if (res.valid) {
          setParentState((s) => ({
            ...s,
            discount: { loading: false, ...res },
          }));
          setTimeout(
            () =>
              setParentState((s) => ({
                ...s,
                discount: { loading: false, ...res },
              })),
            500
          );
        } else {
          setParentState((s) => ({
            ...s,
            discount: { loading: false, error: "Invalid code provided" },
          }));
        }
      } else {
        setParentState((s) => ({
          ...s,
          discount: { loading: false, error: "Invalid code provided" },
        }));
      }
    } catch (err) {
      setParentState((s) => ({
        ...s,
        discount: { loading: false, error: "An error occured" },
      }));
    }
  };

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className="space-y-6"
      action="#"
      method="POST"
    >
      <div>
        {state.loading && (
          <div className="fixed inset-0 z-50 flex justify-center items-center">
            <div className="relative z-50">
              <svg
                viewBox="0 0 44 44"
                width="64"
                height="64"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#75ccc9"
              >
                <g fill="none" fillRule="evenodd" strokeWidth="2">
                  <circle cx="22" cy="22" r="1">
                    <animate
                      attributeName="r"
                      begin="0s"
                      dur="1.8s"
                      values="1; 20"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.165, 0.84, 0.44, 1"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="stroke-opacity"
                      begin="0s"
                      dur="1.8s"
                      values="1; 0"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.3, 0.61, 0.355, 1"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle cx="22" cy="22" r="1">
                    <animate
                      attributeName="r"
                      begin="-0.9s"
                      dur="1.8s"
                      values="1; 20"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.165, 0.84, 0.44, 1"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="stroke-opacity"
                      begin="-0.9s"
                      dur="1.8s"
                      values="1; 0"
                      calcMode="spline"
                      keyTimes="0; 1"
                      keySplines="0.3, 0.61, 0.355, 1"
                      repeatCount="indefinite"
                    />
                  </circle>
                </g>
              </svg>
            </div>
            <div className="fixed inset-0 bg-white opacity-25 z-40" />
          </div>
        )}
        <div className="relative pt-4 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2 w-full">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0 shadow-lg p-6 rounded-lg mt-10 lg:mt-0 flex flex-col">
            <div className="">
              <h2 className="text-left font-extrabold tracking-tight text-xl">
                Your selected courses
              </h2>
              {/* {freeEligible && (
                <div className="bg-brand-600 text-white rounded p-2 text-sm mt-2 -mb-4">
                  <p>
                    Don&apos;t miss out! Book 3 courses and get the lowest
                    priced course for free exclusively in June!
                  </p>
                </div>
              )} */}
              <div className="pt-6 space-y-3 divide-y divide-gray-200">
                {fields.map((item, index) => {
                  const coursesWithSpaces =
                    item.event &&
                    item.event.events &&
                    item.event.events.filter(
                      (x) => x.registrations.length !== x.seats
                    );
                  const formData = form.register(`courses.${index}.date`);
                  const onChange = (e) => {
                    const idx = item.event.events.find(
                      (y) => y.id === e.target.value
                    );
                    courseFields.update(index, {
                      ...item,
                      selectedEvent: idx,
                    });
                    formData.onChange(e);
                  };

                  const pricingItem =
                    item &&
                    item.fields &&
                    item.fields.content.find(
                      (x) => x.sys.contentType.sys.id === "pricing"
                    );
                  return (
                    <div
                      key={`course-enroll-${item.id}`}
                      className="py-3 grid grid-cols-3"
                    >
                      <div className="col-span-3 flex">
                        <h3 className="font-extrabold tracking-tight text-base flex-1">
                          {
                            // @ts-ignore
                            item.fields && item.fields.title
                          }
                        </h3>

                        <div className="ml-2">
                          {((item.event && item.event.standardPrice) ||
                            (item.selectedEvent &&
                              item.selectedEvent.price)) && (
                            <p className="font-extrabold tracking-tight text-base">
                              {item.event && item.event.wasPrice > 0 && (
                                <span className="wasPrice mr-2">
                                  {new Intl.NumberFormat("en-GB", {
                                    style: "currency",
                                    currency: "GBP",
                                  }).format(item.event.wasPrice / 100)}
                                </span>
                              )}
                              {item.package
                                ? new Intl.NumberFormat("en-GB", {
                                    style: "currency",
                                    currency: "GBP",
                                  }).format(item.event.standardPrice / 100)
                                : null}
                              {!item.package && item.selectedEvent
                                ? new Intl.NumberFormat("en-GB", {
                                    style: "currency",
                                    currency: "GBP",
                                  }).format(item.selectedEvent.price / 100)
                                : null}
                              {!item.package && !item.selectedEvent
                                ? new Intl.NumberFormat("en-GB", {
                                    style: "currency",
                                    currency: "GBP",
                                  }).format(item.event.standardPrice / 100)
                                : null}
                            </p>
                          )}
                        </div>
                        <div className="ml-2">
                          <button
                            onClick={() => {
                              session.removeCourse(item.event.id);
                              remove(index);
                            }}
                            className="flex-1 text-sm text-gray-500 md:pb-3"
                          >
                            <XCircleIcon className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                      {item.kitName ? (
                        <div className="col-span-3 pt-4 md:flex items-center">
                          {item.freeKit && (
                            <>
                              <label className="md:flex-1">
                                Includes a{" "}
                                <span className="freePrice">FREE</span>{" "}
                                {item.kitName}{" "}
                                {item.kitWorthPrice
                                  ? `(worth £${item.kitWorthPrice}!)`
                                  : ``}
                              </label>
                            </>
                          )}
                          {!item.freeKit && (
                            <>
                              <label
                                htmlFor={`courses.${index}.includeKit`}
                                className="md:flex-1"
                              >
                                Include a {item.kitName}{" "}
                                {item.kitWorthPrice
                                  ? `(worth £${item.kitWorthPrice}!)`
                                  : ``}
                              </label>
                              <select
                                id={`courses.${index}.includeKit`}
                                className="w-full md:w-auto shadow-sm focus:ring-brand-500 focus:border-brand-600 block sm:text-sm border-gray-300 rounded-md"
                                {...form.register(
                                  `courses.${index}.includeKit`
                                )}
                              >
                                <option value="Yes" key="Yes">
                                  Yes
                                  {item.freeKit
                                    ? " (FREE)"
                                    : " +(£" + item.kitPrice + ")"}
                                </option>
                                <option value="No" key="No">
                                  No
                                </option>
                              </select>
                            </>
                          )}
                        </div>
                      ) : null}
                      {coursesWithSpaces && coursesWithSpaces.length > 0 ? (
                        <div className="col-span-3 pt-2 md:flex items-center">
                          <label
                            htmlFor={`courses.${index}.date`}
                            className="md:flex-1 text-sm"
                          >
                            Select course date:
                          </label>
                          <select
                            id={`courses.${index}.date`}
                            className="w-full md:w-auto shadow-sm focus:ring-brand-500 focus:border-brand-600 block text-sm border-gray-300 rounded-md p-2"
                            {...formData}
                            onChange={onChange}
                          >
                            {coursesWithSpaces.map((d) => {
                              return (
                                <option value={d.id} key={d.id}>
                                  {format(new Date(d.start), "EEEE do MMMM")} -{" "}
                                  {d.type === "onsite"
                                    ? "On site"
                                    : d.type === "virtual"
                                    ? "Virtual"
                                    : d.type === "blended"
                                    ? "Blended"
                                    : ""}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : null}
                      {pricingItem ? (
                        <>
                          {pricingItem.fields.freeKit ? (
                            <div className="mt-3 col-span-3 w-full md:flex items-center text-sm p-2 border border-brand-300 rounded">
                              <p>Included: {pricingItem.fields.kitName}</p>
                            </div>
                          ) : null}
                          {!pricingItem.fields.freeKit &&
                          pricingItem.fields.kitName &&
                          pricingItem.fields.kitPrice ? (
                            <div className="text-sm mt-3 col-span-3 w-full md:flex items-center p-2 border border-brand-300 rounded">
                              <label className="text-sm flex items-center flex-1">
                                <span className="flex-1">
                                  Add: {pricingItem.fields.kitName} for £
                                  {pricingItem.fields.kitPrice}
                                </span>
                                <input
                                  {...form.register(
                                    `courses.${index}.includeKit`
                                  )}
                                  type="checkbox"
                                  className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded"
                                />
                              </label>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
            {freeDiscount === 0 &&
            (!discount || (discount && !discount.valid)) ? (
              <div className="grid md:grid-cols-3 pt-4 mt-3 border-t border-gray-200 items-start">
                <div className="md:col-span-1">
                  <label
                    htmlFor="discount"
                    className="block text-sm font-medium text-gray-700 mb-2 md:mb-0 md:pt-2"
                  >
                    Discount code
                  </label>
                </div>
                <div className="flex flex-col items-end md:col-span-2">
                  <div className="flex-1 w-full">
                    <div className="flex items-center">
                      <input
                        type="text"
                        name="discount"
                        id="discount"
                        {...form.register("discount")}
                        className="shadow-sm focus:ring-brand-500 focus:border-brand-600 block w-full text-sm border-gray-300 rounded-md flex-1 mr-2"
                        placeholder="Enter code"
                      />
                      <button
                        disabled={discount && discount.loading}
                        type="button"
                        onClick={() => checkDiscountCode()}
                        className="group relative flex-0 inline-flex items-center justify-center px-4 py-2 rounded-md shadow text-sm font-medium text-gray-600 border border-gray-300 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                      >
                        {!discount || (discount && !discount.loading)
                          ? "Apply"
                          : null}
                        {discount && discount.loading ? "Loading" : null}
                      </button>
                    </div>
                    {discount && discount.missing && (
                      <span className="text-red-600 text-sm">
                        Discount code required
                      </span>
                    )}
                    {discount && discount.error && (
                      <span className="text-red-600 text-sm">
                        {discount.error}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {freeDiscount === 0 &&
            discount &&
            discount.valid &&
            discount.type &&
            fullTotal ? (
              <div className="text-sm grid grid-cols-2 pt-4 mt-3 border-t border-gray-200">
                <div>
                  <p>Your discount:</p>
                </div>
                <div className="flex flex-col items-end">
                  <input
                    type="hidden"
                    name="discount_code"
                    value={discount.code}
                    {...form.register("discount_code")}
                  />
                  <input
                    type="hidden"
                    name="discount_valid"
                    value={discount.valid}
                    {...form.register("discount_valid")}
                  />
                  <input
                    type="hidden"
                    name="discount_value"
                    value={discount.value}
                    {...form.register("discount_value")}
                  />
                  <input
                    type="hidden"
                    name="discount_id"
                    value={discount.id}
                    {...form.register("discount_id")}
                  />
                  <input
                    type="hidden"
                    name="discount_type"
                    value={discount.type}
                    {...form.register("discount_type")}
                  />
                  <p className="text-sm font-extrabold tracking-tight">
                    {discount.type === "pound"
                      ? new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                        }).format(Number(discount.value) / 100)
                      : null}
                    {discount.type === "percent" &&
                    !isNaN(
                      ((Number(currentValues.discount_value) / 100) *
                        fullTotal) /
                        100
                    )
                      ? new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: "GBP",
                        }).format(
                          ((Number(currentValues.discount_value) / 100) *
                            fullTotal) /
                            100
                        )
                      : null}
                  </p>
                </div>
              </div>
            ) : null}
            {freeDiscount > 0 && lowestFree && (
              <div className="text-sm grid grid-cols-2 pt-4 mt-3 border-t border-gray-200">
                <div>
                  <p className="text-sm">Your 3 for 2 discount:</p>
                </div>
                <div className="flex flex-col items-end">
                  <input
                    type="hidden"
                    name="discount_id"
                    value="LOWEST_FREE"
                    {...form.register("discount_id")}
                  />
                  <p className="text-sm font-extrabold tracking-tight">
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    }).format(freeDiscount / 100)}
                  </p>
                </div>
              </div>
            )}
            <div className="text-sm grid grid-cols-2 pt-4 mt-3 border-t border-gray-200">
              <div>
                <p>Your training total:</p>
              </div>
              <div className="flex flex-col items-end">
                <p className="text-sm font-extrabold tracking-tight">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  }).format(total / 100)}
                </p>
              </div>
            </div>
            <div className="text-sm grid grid-cols-2 pt-4 mt-3 border-t border-gray-200">
              <div>
                <p>Booking fee:</p>
              </div>
              <div className="flex flex-col items-end">
                <p className="text-sm font-extrabold tracking-tight">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  }).format(deposit / 100)}
                </p>
              </div>
            </div>
            {total >= 100000 && (
              <div className="rounded bg-brand-100 p-4 mb-4 mt-4">
                {!state.finance && !state.financeDone && (
                  <>
                    <p className="flex items-center font-medium text-sm">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <span>
                        Do you want to spread the cost of your training?
                      </span>
                    </p>
                    <p className="text-xs mt-4">
                      We offer payment plans for costs in excess of £1,000 that
                      are simple, without interest and credits checks.
                    </p>
                    <button
                      type="button"
                      className="text-sm bg-brand-600 py-2 px-5 text-center rounded text-white mt-2 float-right"
                      onClick={() => setState((s) => ({ ...s, finance: true }))}
                    >
                      Apply
                    </button>
                  </>
                )}
                {state.finance && !state.financeDone && (
                  <form onSubmit={financeForm.handleSubmit(financeSubmit)}>
                    <p className="flex items-center font-medium text-sm">
                      <span>Payment Plan Application</span>
                    </p>
                    <div className="md:flex md:space-x-2">
                      <div className="mt-4 flex-1">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          First name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            {...financeForm.register("first_name", {
                              required: true,
                            })}
                            className="shadow-sm focus:ring-brand-500 focus:border-brand-600 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Your name"
                          />
                        </div>
                        {financeForm.formState.errors.first_name && (
                          <span className="text-red-600 text-sm">
                            Name is required
                          </span>
                        )}
                      </div>
                      <div className="mt-4 flex-1">
                        <label
                          htmlFor="surname"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Last name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="surname"
                            id="surname"
                            {...financeForm.register("last_name", {
                              required: true,
                            })}
                            className="shadow-sm focus:ring-brand-500 focus:border-brand-600 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Last name"
                          />
                        </div>
                        {financeForm.formState.errors.last_name && (
                          <span className="text-red-600 text-sm">
                            Last name is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="md:flex md:space-x-2">
                      <div className="mt-4 flex-1">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            {...financeForm.register("email", {
                              required: true,
                            })}
                            className="shadow-sm focus:ring-brand-500 focus:border-brand-600 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="you@example.com"
                          />
                        </div>
                        {financeForm.formState.errors.email && (
                          <span className="text-red-600 text-sm">
                            Email is required
                          </span>
                        )}
                      </div>
                      <div className="mt-4 flex-1">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Phone number
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            {...financeForm.register("phone_number", {
                              required: true,
                            })}
                            className="shadow-sm focus:ring-brand-500 focus:border-brand-600 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Phone number"
                          />
                        </div>
                        {financeForm.formState.errors.phone_number && (
                          <span className="text-red-600 text-sm">
                            Phone number is required
                          </span>
                        )}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="text-sm bg-brand-600 py-2 px-5 text-center rounded text-white mt-4 float-right hover:bg-brand-700"
                      onClick={(e) => {
                        financeForm.handleSubmit(financeSubmit)();
                      }}
                    >
                      Apply
                    </button>
                  </form>
                )}
                {state.financeDone && (
                  <>
                    <p className="flex items-center font-medium text-sm">
                      <span>Details Received</span>
                    </p>
                    <p className="text-xs mt-4">
                      We have sucessfully received your details and this has
                      been passed on to the team. The team aim to respond within
                      2 hours of submission during business hours.
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="relative max-w-md mx-auto sm:max-w-lg lg:mx-0 shadow-lg p-6 rounded-lg mt-10 lg:mt-0 flex flex-col">
            <input
              type="hidden"
              name="selected"
              defaultValue={mode === "full" ? "klarna" : "card"}
              {...form.register("selected")}
            />
            <div className="flex flex-col flex-1">
              <div className="">
                <div className="pb-4 flex-1">
                  <div className="md:flex md:space-x-2">
                    <div className="mt-4 flex-1">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          {...form.register("name")}
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-600 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Your name"
                        />
                      </div>
                      {form.formState.errors.name && (
                        <span className="text-red-600 text-sm">
                          Name is required
                        </span>
                      )}
                    </div>
                    <div className="mt-4 flex-1">
                      <label
                        htmlFor="surname"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="surname"
                          id="surname"
                          {...form.register("surname")}
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-600 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Last name"
                        />
                      </div>
                      {form.formState.errors.surname && (
                        <span className="text-red-600 text-sm">
                          Last name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="md:flex md:space-x-2">
                    <div className="mt-4 flex-1">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          {...form.register("email")}
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-600 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="you@example.com"
                        />
                      </div>
                      {form.formState.errors.email && (
                        <span className="text-red-600 text-sm">
                          Email is required
                        </span>
                      )}
                    </div>
                    <div className="mt-4 flex-1">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone number
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          {...form.register("phone")}
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-600 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Phone number"
                        />
                      </div>
                      {form.formState.errors.phone && (
                        <span className="text-red-600 text-sm">
                          Phone number is required
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="mt-6">
                    <PaymentElement />
                  </div> */}
                </div>
              </div>
              {state.error && (
                <div className="w-full mt-4">
                  <p className="text-sm text-red-500">{state.error}</p>
                </div>
              )}
              {!thirdParty && (
                <div className="w-full border-t pt-4 md:border-0 mt-2 flex flex-col">
                  <button
                    type="button"
                    onClick={() => payBookingFee()}
                    className="group relative w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow text-base font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                  >
                    Pay booking fee{" "}
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    }).format(deposit / 100)}
                  </button>
                  <span className="text-center my-4 block text-xs w-full text-gray-400">
                    With{" "}
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    }).format((total - deposit) / 100)}{" "}
                    due 14 days before training
                  </span>
                  <span className="text-center mb-4 flex items-center">
                    <span className="flex-1 border-t mt-[2px]" />
                    <span className="mx-2 text-xs text-gray-400">or</span>
                    <span className="flex-1 border-t mt-[2px]" />
                  </span>
                  <button
                    type="button"
                    onClick={() => payInFull()}
                    className="group relative w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow text-base font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                  >
                    Pay in full{" "}
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    }).format(total / 100)}
                  </button>
                </div>
              )}
              {thirdParty && (
                <div className="w-full border-t pt-4 md:border-0 mt-2 flex flex-col md:flex-row">
                  <button
                    type="submit"
                    className="group relative w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow text-base font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 md:mr-4"
                  >
                    Pay with Klarna
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

type TopState = {
  run: boolean;
  fee: {
    id: string;
    client_secret: string;
    amount: number;
  } | null;
  mode: "full" | "fee";
  orderId: string | null;
  total: number;
  deposit: number;
  thirdParty: boolean;
};

const appearance = {
  theme: "none",
  variables: {
    fontFamily: "Montserrat, Arial, sans-serif",
    fontLineHeight: "1.5",
    borderRadius: "8px",
    colorBackground: "#fff",
    spacingUnit: "6px",
  },
  rules: {
    ".Block": {
      border: "none",
    },
    ".Error": {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "16px",
      color: "rgba(220, 38, 38, 1)",
      marginTop: "8px",
    },
    ".Input": {
      fontSize: "14px",
      fontWeight: "400",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(224, 224, 224, 1)",
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingTop: "8px",
      paddingBottom: "8px",
      outline: "none",
      transition: "none",
      lineHeight: "20px",
    },
    ".Input:focus": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "rgb(12, 148, 152, 1)",
      transition: "none",
    },
    ".Input:disabled, .Input--invalid:disabled": {
      color: "lightgray",
      transition: "none",
    },
    ".Input--invalid": {
      color: "rgba(249, 128, 128, 1)",
    },
    ".Tab": {
      padding: "10px 12px 8px 12px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "rgba(224, 224, 224, 1)",
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      transition: "none",
    },
    ".Tab:hover": {
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      transition: "none",
    },
    ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "rgb(12, 148, 152, 1)",
      backgroundColor: "#fff",
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      color: "#262626",
      transition: "none",
    },
    ".Label": {
      fontWeight: "500",
      color: "rgba(97, 97, 97, 1)",
    },
    ".RedirectText": {
      fontWeight: "500",
      color: "rgba(97, 97, 97, 1)",
    },
  },
};

const ADMIN_FEE = 10000;

async function createIntent(
  amount: number,
  customerId?: string | null | undefined,
  email?: string | null | undefined
): Promise<{
  id: string;
  client_secret: string;
  amount: number;
} | null> {
  const req = await fetch("/api/checkout/", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      amount: amount,
      customerId,
      email,
    }),
  });
  const json = await req.json();
  return json;
}

async function updateIntent(
  intent: string,
  amount: number,
  customerId?: string | null | undefined,
  email?: string | null | undefined,
  data?: any
): Promise<{
  id: string;
  client_secret: string;
  amount: number;
} | null> {
  const req = await fetch("/api/checkout/", {
    method: "put",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      intent,
      amount: amount,
      customerId,
      email,
      metadata: data,
    }),
  });
  const json = await req.json();
  return json;
}

export function getPrices(currentValues) {
  let total = 0;
  let freeDiscount = 0;
  const prices = currentValues.courses
    .map((a) => {
      if (a.event && a.event.package) {
        return null;
      }
      const pricingItem =
        a.fields &&
        a.fields.content.find((x) => x.sys.contentType.sys.id === "pricing");

      if (
        a.includeKit &&
        pricingItem &&
        pricingItem.fields.kitPrice &&
        pricingItem.fields.kitPrice > 0
      ) {
        return a.selectedEvent.price + pricingItem.fields.kitPrice * 100;
      }

      if (a.selectedEvent && a.selectedEvent.price) {
        return a.selectedEvent.price;
      }
    })
    .filter(Boolean);

  const min = Math.min(...prices);

  let fullTotal = currentValues.courses.reduce((x, a) => {
    if (a.event && a.event.package) {
      return x + a.event.standardPrice;
    }

    const pricingItem =
      a.fields &&
      a.fields.content.find((x) => x.sys.contentType.sys.id === "pricing");

    if (
      a.includeKit &&
      pricingItem &&
      pricingItem.fields.kitPrice &&
      pricingItem.fields.kitPrice > 0
    ) {
      return x + a.selectedEvent.price + pricingItem.fields.kitPrice * 100;
    }

    if (a.selectedEvent && a.selectedEvent.price) {
      return x + a.selectedEvent.price;
    }

    return a;
  }, 0);

  if (lowestFree && prices.length === 3) {
    total = fullTotal - min;
    freeDiscount = min;
  } else if (
    currentValues.discount_id &&
    currentValues.discount_type === "percent"
  ) {
    const percent = Number(currentValues.discount_value) / 100;
    total = fullTotal - percent * fullTotal;
  } else if (
    currentValues.discount_id &&
    currentValues.discount_type === "pound"
  ) {
    total = fullTotal - Number(currentValues.discount_value);
  } else {
    total = fullTotal;
  }

  const dep = Math.round(0.1 * total);
  const deposit = ADMIN_FEE * currentValues.courses.length;
  // dep > 5000 ? dep : 5000;

  return {
    total,
    fullTotal,
    deposit,
    freeDiscount,
    freeEligible: prices.length < 3 && lowestFree,
  };
}

function Enroll(props) {
  const session = useSession();
  const firstRenderRef = useRef(true);
  const [state, setState] = useState<TopState>({
    fee: null,
    mode: "full",
    orderId: null,
    run: true,
    loading: true,
    total: 0,
    deposit: 0,
    thirdParty: false,
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      selected: "card",
      name: "",
      surname: "",
      email: "",
      phone: "",
      courses: session.selectedCourses || [],
    },
  });

  const courseFields = useFieldArray({
    control: form.control,
    // @ts-ignore
    name: "courses",
  });

  const currentValues = form.watch();

  async function changeMode(mode, thirdParty = false) {
    form.setValue("selected", thirdParty ? "klarna" : "card");
    return setState((s) => {
      return {
        ...s,
        mode,
        thirdParty,
        ...getPrices(currentValues),
      };
    });
  }

  async function createOrder(data: any) {
    let cookieData = {};
    try {
      cookieData = JSON.parse(cookies.get("ccmgd"));
    } catch (err) {
      console.log(err);
    }
    const req = await fetch("/api/enroll/", {
      method: "post",
      body: JSON.stringify({
        data: {
          ...data,
          metadata: {
            ...cookieData,
            ...(session.sourceParams || {}),
          },
        },
      }),
    });

    const res = await req.json();
    localStorage.setItem("cc.orderId2", res.id);
    setState((s) => ({
      ...s,
      orderId: res.id,
    }));
    return res;
  }

  async function updateOrder(id: string, data: any) {
    let cookieData = {};
    try {
      cookieData = JSON.parse(cookies.get("ccmgd"));
    } catch (err) {
      console.log(err);
    }
    const req = await fetch("/api/enroll/", {
      method: "put",
      body: JSON.stringify({
        id,
        data: {
          ...data,
          metadata: {
            ...cookieData,
            ...(session.sourceParams || {}),
          },
        },
      }),
    });
    const res = await req.json();
    localStorage.setItem("cc.orderId2", res.id);
    setState((s) => ({
      ...s,
      orderId: res.id,
    }));
    return res;
  }

  useEffect(() => {
    if (!state.orderId) {
      const orderIdFromStorage = localStorage.getItem("cc.orderId2");
      if (orderIdFromStorage) {
        setState((s) => ({ ...s, orderId: orderIdFromStorage }));
      } else if (session.profile && session.profile.id) {
        createOrder(profile.id);
      }
    }
  }, [session.profile]);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      createIntent(
        ADMIN_FEE,
        session.user && session.user.account,
        session.user && session.user.email
      )
        .then((fee) => {
          setState((s) => ({
            ...s,
            fee,
            loading: false,
          }));
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });

      if (session.selectedCourses.length > 0) {
        try {
          const t = getPrices({ courses: session.selectedCourses });
          window.gtag &&
            window.gtag("event", "begin_checkout", {
              currency: "GBP",
              value: t.total / 100,
              items: session.selectedCourses.map((x, i) => {
                return {
                  item_id: x.event.id,
                  item_name: x.fields.title,
                  affiliation: "Cosmetic College",
                  currency: "GBP",
                  index: i,
                  item_brand: "Cosmetic College",
                  item_category: "Courses",
                  item_category2: x.fields.categories[0],
                  item_list_id: x.fields.categories[0],
                  item_list_name: capitalize(x.fields.categories[0]),
                  price: x.package
                    ? x.event.standardPrice / 100
                    : x.selectedEvent
                    ? x.selectedEvent.price / 100
                    : x.event.standardPrice / 100,
                  quantity: 1,
                };
              }),
            });
          // @ts-ignore
          window.fbq &&
            window.fbq("track", "InitiateCheckout", {
              value: t.total / 100,
              currency: "GBP",
              content_name: "Checkout",
              content_category: "product",
              content_type: "product",
              content_ids: session.selectedCourses.map((x) => x.event.id),
              num_ids: session.selectedCourses.length,
            });
        } catch (err) {
          console.log(err);
          Sentry.captureException(err);
        }
      }

      return;
    }
  }, []);

  useEffect(() => {
    if (session.selectedCourses.length > 0) {
      // if (session.selectedCourses.length !== currentValues.courses.length) {
      //   const courses = session.selectedCourses.map((x, t) => {
      //     const pricing = x.fields.content.find(
      //       (i) => i.sys.contentType.sys.id === "pricing"
      //     );
      //     if (x.package) {
      //       return {
      //         title: x.fields.title,
      //         event: x.sys.id,
      //         price: x.event.standardPrice,
      //         wasPrice: x.event.wasPrice,
      //         date: null,
      //         kitPrice: pricing.fields.kitPrice,
      //         includeKit: "Yes",
      //         package: true,
      //         freeKit: pricing.fields.freeKit,
      //         kitName: pricing.fields.kitName,
      //         kitWorthPrice: pricing.fields.kitWorthPrice,
      //       };
      //     } else {
      //       return {
      //         title: x.fields.title,
      //         event: x.event.id,
      //         price: x.selectedEvent.price / 100,
      //         wasPrice: x.event.wasPrice || 0,
      //         date: x.selectedEvent.id,
      //         kitPrice: pricing.fields.kitPrice,
      //         includeKit: "Yes",
      //         package: false,
      //         freeKit: pricing.fields.freeKit,
      //         kitName: pricing.fields.kitName,
      //         kitWorthPrice: pricing.fields.kitWorthPrice,
      //       };
      //     }
      //   });
      //   courseFields.append(courses);
      // }

      const { total, deposit } = getPrices(currentValues);

      setState((s) => ({
        ...s,
        total,
        deposit,
      }));
    }
  }, [session.selectedCourses, courseFields.fields]);

  if (!state.fee && session.selectedCourses.length > 0) {
    return (
      <Layout siteData={props.siteData}>
        <div className="fixed inset-0 z-50 flex justify-center items-center">
          <div className="relative z-50">
            <svg
              viewBox="0 0 44 44"
              width="64"
              height="64"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#75ccc9"
            >
              <g fill="none" fillRule="evenodd" strokeWidth="2">
                <circle cx="22" cy="22" r="1">
                  <animate
                    attributeName="r"
                    begin="0s"
                    dur="1.8s"
                    values="1; 20"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.165, 0.84, 0.44, 1"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="stroke-opacity"
                    begin="0s"
                    dur="1.8s"
                    values="1; 0"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.3, 0.61, 0.355, 1"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="22" cy="22" r="1">
                  <animate
                    attributeName="r"
                    begin="-0.9s"
                    dur="1.8s"
                    values="1; 20"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.165, 0.84, 0.44, 1"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="stroke-opacity"
                    begin="-0.9s"
                    dur="1.8s"
                    values="1; 0"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.3, 0.61, 0.355, 1"
                    repeatCount="indefinite"
                  />
                </circle>
              </g>
            </svg>
          </div>
          <div className="fixed inset-0 bg-white opacity-25 z-40" />
        </div>
      </Layout>
    );
  }

  const { total, fullTotal, deposit, freeDiscount, freeEligible } =
    getPrices(currentValues);

  return (
    <Layout siteData={props.siteData}>
      <Elements
        stripe={getStripe()}
        options={{
          clientSecret: state.fee.client_secret,
          appearance,
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,500,600",
            },
          ],
        }}
      >
        <EnrollCmp
          {...props}
          {...state}
          setState={setState}
          form={form}
          session={session}
          currentCourses={currentValues.courses}
          currentValues={currentValues}
          courseFields={courseFields}
          createOrder={createOrder}
          updateOrder={updateOrder}
          changeMode={changeMode}
          total={total}
          fullTotal={fullTotal}
          deposit={deposit}
          freeDiscount={freeDiscount}
          freeEligible={freeEligible}
        />
      </Elements>
    </Layout>
  );
}

export default function Setup(props) {
  const session = useSession();

  if (session.loading) {
    return (
      <Layout siteData={props.siteData}>
        <div className="fixed inset-0 z-50 flex justify-center items-center">
          <div className="relative z-50">
            <svg
              viewBox="0 0 44 44"
              width="64"
              height="64"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#75ccc9"
            >
              <g fill="none" fillRule="evenodd" strokeWidth="2">
                <circle cx="22" cy="22" r="1">
                  <animate
                    attributeName="r"
                    begin="0s"
                    dur="1.8s"
                    values="1; 20"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.165, 0.84, 0.44, 1"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="stroke-opacity"
                    begin="0s"
                    dur="1.8s"
                    values="1; 0"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.3, 0.61, 0.355, 1"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="22" cy="22" r="1">
                  <animate
                    attributeName="r"
                    begin="-0.9s"
                    dur="1.8s"
                    values="1; 20"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.165, 0.84, 0.44, 1"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="stroke-opacity"
                    begin="-0.9s"
                    dur="1.8s"
                    values="1; 0"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.3, 0.61, 0.355, 1"
                    repeatCount="indefinite"
                  />
                </circle>
              </g>
            </svg>
          </div>
          <div className="fixed inset-0 bg-white opacity-25 z-40" />
        </div>
      </Layout>
    );
  }

  if (!session.loading && session.selectedCourses.length === 0) {
    return (
      <Layout siteData={props.siteData}>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2 w-full">
          <div className="lg:pr-8">
            <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
                Lets get you enrolled
              </h2>
              <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                It appears you have not selected any courses.
              </p>
              <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                <Link
                  href="/courses/"
                  className="font-medium text-brand-500 hover:text-brand-600"
                >
                  Please select a course to enroll onto.
                </Link>
              </p>
            </div>
          </div>
          <div />
        </div>
      </Layout>
    );
  }

  return <Enroll {...props} />;
}

export async function getStaticProps({ params, preview = false }) {
  const siteData = await loadStandardContent();
  return {
    props: {
      siteData,
    },
  };
}
